import React, {ReactNode} from "react";
import {Layout as AntdLayout} from 'antd';
import {useLocation} from "react-router-dom";

import ThemeContext from "../context/ThemeContext";
import Header from "./Header";
import Footer from "./Footer";

interface Props {
    children: ReactNode
}

const Layout = ({children}: Props) => {
    const location = useLocation();
    const ccMode = location.pathname.startsWith("/cc");
    const ccHeader = "#00a6e2";
    const ccContent = "#f4f4f4";

    return (
        <ThemeContext.Consumer>
            {({containerWidth, containerWidthInner}) => (
                <AntdLayout style={{height: "100vh", maxWidth: containerWidth, margin: "0 auto"}}>
                    <AntdLayout.Header style={ccMode ? {background: ccHeader} : undefined}>
                        <div style={{maxWidth: containerWidthInner, margin: "0 auto"}}>
                            <Header ccMode={ccMode}/>
                        </div>
                    </AntdLayout.Header>
                    <AntdLayout>
                        <AntdLayout.Content style={{
                            padding: "0 1.25rem 1rem",
                            overflow: "auto",
                            background: ccMode ? ccContent : "inherit"
                        }}>
                            <div style={{maxWidth: containerWidthInner, margin: "0 auto"}}>
                                {children}
                            </div>
                        </AntdLayout.Content>
                    </AntdLayout>
                    <AntdLayout.Footer style={ccMode ? {background: ccHeader} : undefined}>
                        <div style={{maxWidth: containerWidthInner, margin: "0 auto"}}>
                            <Footer ccMode={ccMode}/>
                        </div>
                    </AntdLayout.Footer>
                </AntdLayout>)}
        </ThemeContext.Consumer>
    )
}

export default Layout;