import React, {useContext, useEffect} from "react";
import {Alert, Card, Col, Row} from "antd";

import LocalizationContext from "../../context/LocalizationContext";
import Headline from "../../layout/Headline";
import Breadcrumbs from "../../layout/Breadcrumbs";
import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import CalendarCreate from "./CalendarCreate";
import {Link, useLocation} from "react-router-dom";
import {ROUTE_CALENDAR, ROUTE_CALENDAR_CREATE, ROUTE_PRE_CHECKIN} from "../../../constant/route";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {calendarMocks} from "../../../mock/calendar";
import {formatDate} from "../vehicle/util";

const Calendar = () => {
    const {t} = useContext(LocalizationContext);
    const location = useLocation();

    const [create, setCreate] = React.useState(location.pathname === ROUTE_CALENDAR_CREATE);
    const [submitted, setSubmitted] = React.useState(false);

    useEffect(() => {
        setCreate(location.pathname === ROUTE_CALENDAR_CREATE);
        setSubmitted(false)
    }, [location.pathname]);

    return (
        <>
            <Breadcrumbs/>
            <Headline actions={!create && <Link
              to={ROUTE_CALENDAR_CREATE}>{t("route." + ROUTE_CALENDAR_CREATE)}</Link>}>{t("calendar.headline")}</Headline>

            {calendarMocks
                .map(calendar => (
                    <Card size={"small"} key={calendar.id}>
                        <Row gutter={VIEW_GUTTER} align={"middle"}>
                            <Col span={4}>
                                {formatDate(calendar.date)}
                            </Col>
                            <Col span={4}>
                                {calendar.time}
                            </Col>
                            <Col span={8}>
                                <strong>{calendar.title}</strong>
                            </Col>
                            <Col span={8} style={{textAlign: "right"}}>
                                {calendar.preCheckIn && <Link to={ROUTE_PRE_CHECKIN}>
                                  <FontAwesomeIcon icon={faListCheck} style={{marginRight: ".5rem"}}/>
                                    {t("preCheckIn.available")}
                                </Link>}
                            </Col>
                        </Row>
                    </Card>
                ))}

            {create &&
              <Card style={{marginTop: "1.5rem"}} title={t("route." + ROUTE_CALENDAR_CREATE)}
                    extra={<Link to={ROUTE_CALENDAR}><FontAwesomeIcon icon={faTimes}/></Link>}>
                  {submitted ?
                      <Alert
                          message={"Vielen Dank für Ihre Terminanfrage, wir werden uns schnellstmöglich bei Ihnen\n" +
                              "                          melden!"}/> :
                      <CalendarCreate submit={() => setSubmitted(true)}/>}
              </Card>}
        </>
    )
}

export default Calendar;