import React, {useContext} from "react";
import {Card, TableProps} from "antd";
import {useNavigate} from "react-router-dom";

import LocalizationContext from "../../context/LocalizationContext";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import Date from "../../shared/Date";
import NavButton from "../../layout/NavButton";
import {ROUTE_MESSAGE, ROUTE_MESSAGES} from "../../../constant/route";
import {messageMocks} from "../../../mock/messages";

const Messages = () => {
    const {t} = useContext(LocalizationContext);
    const navigate = useNavigate();

    const columns: TableProps["columns"] = [{
        title: t("dashboard.messages.field.sender"),
        key: "sender",
        dataIndex: "sender",
    }, {
        title: t("dashboard.messages.field.subject"),
        key: "subject",
        dataIndex: "subject",
    }, {
        title: t("dashboard.messages.field.createdAt"),
        key: "date",
        dataIndex: "date",
        align: "right",
        render: (createdAt: string) => <Date value={createdAt}/>
    }]

    const open = (messageId: string) => navigate(ROUTE_MESSAGE.replace(":id", messageId))

    const extra = <NavButton type={"link"} to={ROUTE_MESSAGES}>{t("dashboard.msgLink")}</NavButton>;

    return (
        <>
            <Card title={t("dashboard.messages.headline")} extra={extra}>
                <Table columns={columns} dataSource={messageMocks.slice(0, 3)}
                       rowClassName={x => x.read ? "" : TABLE_ROW_BOLD}
                       onRow={(m) => {
                           return {onClick: () => open(m.id)};
                       }}/>
            </Card>
        </>
    )
}

export default Messages;