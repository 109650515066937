import {DocumentMock, documentMocks} from "../../../mock/document";
import React, {useContext, useState} from "react";
import LocalizationContext from "../../context/LocalizationContext";
import Date from "../../shared/Date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, Row, TableProps} from "antd";
import {Col3} from "../../layout/Grid";
import {compareStrings} from "../../../util/sort";
import uniq from "lodash.uniq";
import {formatDate} from "../vehicle/util";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";

interface Props {
    document: DocumentMock
}

export const DocumentContent = ({document}: Props) => {
    const {t} = useContext(LocalizationContext);

    return (
        <>
            <div style={{textAlign: "right"}}><em><Date value={document.date}/></em></div>
            <div style={{marginBottom: "2rem"}}>
                <FontAwesomeIcon icon={faFilePdf} size={"6x"}/>
            </div>
            <Row>
                <Col3>
                    <Button type={"primary"} ghost={true} block={true} href={"./" + document.file} target={"_blank"}>
                        {t("documents.open")}
                    </Button>
                </Col3>
            </Row>
        </>
    )
}


const DocumentsCC = () => {
    const {t} = useContext(LocalizationContext);

    const [document, setDocument] = useState<DocumentMock>();

    const columns: TableProps["columns"] = [{
        title: t("documents.field.topic"),
        key: "topic",
        dataIndex: "topic",
        sorter: compareStrings(x => x.topic),
        filters: uniq(documentMocks.map(m => m.topic)).map(x => ({
            text: x,
            value: x
        })),
        onFilter: (value, record) => record.topic.indexOf(value as string) === 0,
    }, {
        title: t("documents.field.title"),
        key: "title",
        dataIndex: "title",
        sorter: compareStrings(x => x.title)
    }, {
        title: t("documents.field.date"),
        key: "date",
        dataIndex: "date",
        sorter: compareStrings(x => x.date),
        render: x => formatDate(x)
    },]

    const close = (
        <Button type={"link"} size={"small"} onClick={() => setDocument(undefined)}>
            <FontAwesomeIcon icon={faTimes}/>
        </Button>
    )

    return (
        <>
            <Card>
                <Table columns={columns} dataSource={documentMocks}
                       rowClassName={x => x.id === document?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(d: DocumentMock) => {
                           return {onClick: () => setDocument(d)};
                       }}/>
            </Card>
            {document &&
              <Card title={document.title} extra={close}>
                <DocumentContent document={document}/>
              </Card>}
        </>
    )
}

export default DocumentsCC;