export enum ProcessMockStatus {
    NEW = "NEU",
    IN_PROGRESS = "In Arbeit",
    DONE = "Erledigt",
    OVERDUE = "Überfällig"
}

export enum ProcessMockHandler {
    RPA = "RPA",
    API = "API",
    WORKSHOP = "Werkstatt",
    MANUAL = "Manual"
}

export interface ProcessMock {
    id: string,
    type: string,
    title: string,
    vehicle: string,
    customer: string,
    handler: ProcessMockHandler,
    status: ProcessMockStatus,
    user: string,
    date: string,
    hasForm?: boolean
}

export const processMocks = [
    {
        id: "1",
        type: "Angebot",
        title: "Neues Fahrzeugangebot",
        vehicle: "B CD 1234",
        customer: "Max Mustermann",
        handler: ProcessMockHandler.API,
        status: ProcessMockStatus.NEW,
        user: undefined,
        date: "2024-09-01",
    },
    {
        id: "2",
        type: "Fahrzeugpapiere",
        title: "Zulassungspapiere bereitstellen",
        vehicle: "HH AB 5678",
        customer: "Lisa Meier",
        handler: ProcessMockHandler.RPA,
        status: ProcessMockStatus.IN_PROGRESS,
        user: undefined,
        date: "2024-09-05",
    },
    {
        id: "3",
        type: "Vertrag",
        title: "Leasingvertrag unterschreiben",
        vehicle: "M XY 1234",
        customer: "Tom Müller",
        handler: ProcessMockHandler.API,
        status: ProcessMockStatus.DONE,
        user: undefined,
        date: "2024-08-25",
    },
    {
        id: "4",
        type: "Rechnung",
        title: "Werkstattrechnung",
        vehicle: "S KL 9876",
        customer: "Anna Schmidt",
        handler: ProcessMockHandler.WORKSHOP,
        status: ProcessMockStatus.OVERDUE,
        user: undefined,
        date: "2024-08-15",
    },
    {
        id: "5",
        type: "Vertrag",
        title: "Kaufvertrag für Neuwagen",
        vehicle: "B GH 4567",
        customer: "Hans Becker",
        handler: ProcessMockHandler.API,
        status: ProcessMockStatus.NEW,
        user: undefined,
        date: "2024-09-10",
    },
    {
        id: "6",
        type: "Angebot",
        title: "Preisangebot für Inspektion",
        vehicle: "K FD 1234",
        customer: "Sophie Wagner",
        handler: ProcessMockHandler.MANUAL,
        status: ProcessMockStatus.IN_PROGRESS,
        user: "Michael Müller",
        date: "2024-09-02",
    },
    {
        id: "7",
        type: "Fahrzeugpapiere",
        title: "Brief und Schein aktualisieren",
        vehicle: "F ZW 2345",
        customer: "Jürgen Schröder",
        handler: ProcessMockHandler.RPA,
        status: ProcessMockStatus.DONE,
        user: undefined,
        date: "2024-08-30",
    },
    {
        id: "8",
        type: "Rechnung",
        title: "Reparaturkostenabrechnung",
        vehicle: "L LM 6789",
        customer: "Klara Peters",
        handler: ProcessMockHandler.WORKSHOP,
        status: ProcessMockStatus.IN_PROGRESS,
        user: undefined,
        date: "2024-09-07",
    },
    {
        id: "9",
        type: "Vertrag",
        title: "Garantieverlängerung",
        vehicle: "BB XY 912",
        customer: "Sina Huber",
        handler: ProcessMockHandler.MANUAL,
        status: ProcessMockStatus.IN_PROGRESS,
        user: "Alexander Muster",
        date: "2024-10-04",
        hasForm: true
    }
];
