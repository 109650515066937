import {Button, Card, Checkbox, Col, Collapse, CollapseProps, Input, Row, Upload} from "antd";
import Breadcrumbs from "../layout/Breadcrumbs";
import React from "react";
import {InboxOutlined} from '@ant-design/icons';
import {VIEW_GUTTER} from "../../constant/cmsConfig";
import eqb from "../../assets/eqb.png";

const {Dragger} = Upload;

interface Props {
    setCanSubmit: (x: boolean) => void
}

const CheckInForm = ({setCanSubmit}: Props) => {
    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Ihre MercedesMe Dienste',
            children: <em>TODO: Screenshot o.Ä.</em>,
        },
        {
            key: '2',
            label: 'Ihr Fahrzeugschein',
            children: (
                <>
                    <Dragger name={"file"} action={""}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Klicken oder ziehen Sie eine Datei in diesen Bereich, um sie
                            hochzuladen</p>
                    </Dragger>
                </>
            ),
        },
        {
            key: '3',
            label: 'Ihre Datenschutzerklärung',
            children: (
                <>
                    <Checkbox onChange={(e) => setCanSubmit(e.target.checked)}>
                        Hiermit bestätige ich, dass ich die <strong>Datenschutzbestimmungen</strong> gelesen und
                        akzeptiert habe. Indem ich auf „Absenden“ klicke, stimme ich zu, dass meine Daten gemäß den
                        Datenschutzrichtlinien verarbeitet werden.
                    </Checkbox>
                </>
            ),
        },
        {
            key: '4',
            label: 'Weitere Informationen zu Ihrem Fahrzeug',
            children: (
                <p>
                    <Input.TextArea autoSize={{minRows: 3}}
                                    showCount
                                    maxLength={1024}></Input.TextArea>
                </p>
            ),
        },
    ];

    return <Collapse items={items} defaultActiveKey={['1']}/>;
}

const PreCheckin = () => {
    const [submit, setSubmit] = React.useState(false);
    const [canSubmit, setCanSubmit] = React.useState(false);

    return (
        <>
            <Breadcrumbs/>
            <Card title={"Pre-Check-In | Wir freuen uns auf Ihren Besuch am 16.10.2024"}>
                {submit ? (
                    <>
                        <Row gutter={VIEW_GUTTER}>
                            <Col span={16}>
                                <p><strong>Vielen Dank für Ihre Angaben!</strong></p>
                                <p>
                                    Ihre Informationen wurden erfolgreich übermittelt. Wir freuen uns darauf, Sie und
                                    Ihren EQB
                                    (BB-SC 68E)
                                    am
                                    <strong> 16.10.2024 um 17:00 Uhr</strong> an unserem Standort in der
                                    <strong> Musterstraße 1, 71034 Böblingen</strong> begrüßen zu dürfen.
                                </p>
                                <p>
                                    Der Schlüssel Ihres Fahrzeugs kann bequem vor dem Termin abgegeben werden. Den
                                    Status der
                                    Inspektion
                                    können Sie
                                    live in Ihrem Kundenportal verfolgen, und wir halten Sie per E-Mail über den Verlauf
                                    und das
                                    Ergebnis
                                    auf dem Laufenden.
                                </p>
                                <p>
                                    Sollten Sie noch Fragen haben, stehen wir Ihnen gerne zur Verfügung.
                                </p>
                                <p><strong>Wir wünschen Ihnen eine gute Fahrt und bis bald!</strong></p>
                            </Col>
                            <Col span={8}>
                                <img src={eqb} alt={""}/>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <p> Sehr geehrter Herr Mustermann,</p>
                        <p> Am 16.10.2024 dürfen wir Sie und Ihren EQB (BB-SC 68E) um 17:00 Uhr an unserem Standort in
                            der Musterstraße 1, 71034 Böblingen begrüßen.</p>
                        <p>Um uns bestmöglich auf Ihren Besuch vorbereiten zu können, würden wir Sie gerne um fünf
                            Minuten Ihrer Zeit bitten, um vorab folgende Informationen mit uns zu teilen:
                        </p>
                        <Row gutter={VIEW_GUTTER}>
                            <Col span={16}>
                                <CheckInForm setCanSubmit={setCanSubmit}/>
                            </Col>
                            <Col span={8}>
                                <img src={eqb} alt={""}/>
                            </Col>
                        </Row>
                        <p>Mit der Übermittlung dieser Daten, können Sie den Schlüssel Ihres Fahrzeugs ganz bequem vor
                            Ihrem Termin abgeben und müssen nichts weiter tun. Den Status der Inspektion können Sie live
                            im Kundenportal verfolgen und wir informieren Sie zusätzlich per E-Mail über den Verlauf und
                            das Ergebnis.</p>
                        <Button type={"primary"} disabled={!canSubmit} onClick={() => setSubmit(true)}>Pre-Check-In abschließen</Button>
                    </>
                )}

            </Card>
        </>
    )
}

export default PreCheckin;