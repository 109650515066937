import React, {useContext} from "react";
import {Breadcrumb} from "antd";
import {Link, useLocation} from "react-router-dom";

import LocalizationContext from "../../context/LocalizationContext";
import {ROUTE_CC} from "../../../constant/route";

interface Props {
    route?: string,
    id?: string
}

const BreadcrumbsCC = ({route, id}: Props) => {
    const {t} = useContext(LocalizationContext);

    const location = useLocation();
    const resolvedRoute = route || location.pathname;

    const breadcrumbs = [
        {title: <Link to={ROUTE_CC}>Customer Care Center</Link>}
    ];

    breadcrumbs.push({
        title: t("route." + resolvedRoute)
    })

    return (
        <Breadcrumb style={{margin: '1rem 0'}} items={breadcrumbs}/>
    )
}

export default BreadcrumbsCC;