import {getMediaUrl, StrapiEntity} from "../../../type/StrapiApi";
import {StrapiVehicle} from "../../../type/StrapiVehicle";
import {Col, Row, Space} from "antd";
import {Col3, Col3double} from "../../layout/Grid";
import React, {ReactNode, useContext} from "react";
import LocalizationContext from "../../context/LocalizationContext";
import {formatDateMonth} from "./util";

interface Props {
    vehicle: StrapiEntity<StrapiVehicle>,
    actions?: ReactNode,
}

export const VehicleDetailLeft = ({vehicle}: Props) => {
    const {t} = useContext(LocalizationContext);

    const model = vehicle.attributes.model;
    const manufacturer = model?.data?.attributes.manufacturer;

    return (
        <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
            <Row>
                <Col3><strong>{t("form.vehicle.model")}</strong></Col3>
                <Col3double>
                    {model.data?.attributes.name}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.manufacturer")}</strong></Col3>
                <Col3double>
                    {manufacturer?.data?.attributes.name}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.registration")}</strong></Col3>
                <Col3double>
                    {formatDateMonth(vehicle.attributes.registration)}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.inspection")}</strong></Col3>
                <Col3double>
                    {vehicle.attributes.inspection && formatDateMonth(vehicle.attributes.inspection)}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.vin")}</strong></Col3>
                <Col3double>
                    {vehicle.attributes.vin}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.label")}</strong></Col3>
                <Col3double>
                    {vehicle.attributes.label}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.kilometers")}</strong></Col3>
                <Col3double>
                    {vehicle.attributes.kilometers}
                </Col3double>
            </Row>
            <Row>
                <Col3><strong>{t("form.vehicle.status")}</strong></Col3>
                <Col3double>
                    Fahrzeug aktiv
                </Col3double>
            </Row>
        </Space>
    )
}

const VehicleDetail = ({vehicle, actions}: Props) => {
    const model = vehicle.attributes.model;
    const image = model.data?.attributes.image.data;

    return (
        <Row gutter={50}>
            <Col span={actions ? 8 : 16}>
                <VehicleDetailLeft vehicle={vehicle}/>
            </Col>
            {actions &&
              <Col3>
                  {actions}
              </Col3>}
            <Col3>
                {image && <img src={getMediaUrl(image)} alt={""}/>}
            </Col3>
        </Row>
    )
}

export default VehicleDetail;