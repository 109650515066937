export const formatDateMonth = (inputDate: string) => {
    const parts = inputDate.split("-");
    const year = parts[0] || "";
    const month = parts[1] || "";
    return `${month}/${year}`;
}

export const formatDate = (inputDate?: string) => {
    if (!inputDate) return inputDate;

    const parts = inputDate.split("-");
    const year = parts[0] || "";
    const month = parts[1] || "";
    const day = parts[2] || "";
    return `${day}.${month}.${year}`;
}