import React, {useContext, useState} from "react";
import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import {StrapiEntity, StrapiId, StrapiNested} from "../../../type/StrapiApi";
import {StrapiVehicle} from "../../../type/StrapiVehicle";
import {Button, Card, TableProps} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {compareNumbers, compareStrings} from "../../../util/sort";
import {StrapiModel} from "../../../type/StrapiModel";
import {formatDateMonth} from "../vehicle/util";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import VehicleDetail from "../vehicle/VehicleDetail";

type VehicleRecord = StrapiVehicle & { id: StrapiId };

const VehiclesCC = () => {
    const {t} = useContext(LocalizationContext);
    const {allVehicles} = useContext(StrapiContext);

    const [view, setView] = useState<StrapiEntity<StrapiVehicle>>();

    const close = () => {
        setView(undefined)
    }

    const columns: TableProps["columns"] = [{
        title: t("form.vehicle.number"),
        key: "number",
        dataIndex: "number",
        sorter: compareStrings(x => x.number)
    }, {
        title: t("form.vehicle.model"),
        key: "model",
        dataIndex: "model",
        render: (model: StrapiNested<StrapiModel>, vehicle: StrapiVehicle) => {
            const manufacturer = model?.data?.attributes.manufacturer;
            return (
                <>
                    {manufacturer?.data?.attributes.name}
                    {" "}
                    {model?.data?.attributes.name}
                </>
            )
        }
    }, {
        title: t("form.vehicle.registration"),
        key: "registration",
        dataIndex: "registration",
        render: date => formatDateMonth(date),
        sorter: compareStrings(x => x.registration)
    }, {
        title: t("form.vehicle.inspection"),
        key: "inspection",
        dataIndex: "inspection",
        render: date => date && formatDateMonth(date),
        sorter: compareStrings(x => x.inspection)
    }, {
        title: t("form.vehicle.vin"),
        key: "vin",
        dataIndex: "vin",
    }, {
        title: t("form.vehicle.kilometers"),
        key: "kilometers",
        dataIndex: "kilometers",
        sorter: compareNumbers(x => x.kilometers)
    }]

    const viewActions = view && (
        <>
            <Button type={"link"} size={"small"} onClick={close}>
                <FontAwesomeIcon icon={faTimes}/>
            </Button>
        </>
    )

    const dataSource = (allVehicles ?? [])
        .map(v => ({id: v.id, ...v.attributes}))

    return (
        <>
            <Card>
                <Table columns={columns} dataSource={dataSource}
                       rowClassName={x => x.id === view?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(vehicle: VehicleRecord) => {
                           return {
                               onClick: () => {
                                   setView(allVehicles.find(v => v.id === vehicle.id))
                               },
                           };
                       }}/>
            </Card>
            {view
                ? (
                    <Card title={view.attributes.number} extra={viewActions}>
                        <VehicleDetail vehicle={view}/>
                    </Card>
                )
                : ""}
        </>
    )
}

export default VehiclesCC;