export interface CalendarMock {
    id: string,
    title: string,
    time: string,
    date: string,
    preCheckIn?: boolean
}

export const calendarMocks: CalendarMock[] = [
    {
        id: "2",
        title: "Service Mercedes Benz EQB",
        time: "17:00 Uhr",
        date: "2024-10-16",
        preCheckIn: true
    },
    {
        id: "1",
        title: "Übergabe Mercedes Benz EQB",
        time: "09:00 Uhr",
        date: "2024-03-01"
    }
];