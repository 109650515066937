import type {MenuProps} from 'antd';
import {Col, Menu, Row} from "antd";
import BreadcrumbsCC from "./BreadcrumbsCC";

import {VIEW_GUTTER} from "../../../constant/cmsConfig";
import {
    ROUTE_CC,
    ROUTE_CC_CUSTOMERS,
    ROUTE_CC_DOCUMENTS,
    ROUTE_CC_PAYMENTS,
    ROUTE_CC_PROCESSES,
    ROUTE_CC_PROFILE,
    ROUTE_CC_VEHICLES
} from "../../../constant/route";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import React, {useContext} from "react";
import LocalizationContext from "../../context/LocalizationContext";
import VehiclesCC from "./VehiclesCC";
import HomeCC from "./HomeCC";
import CustomersCC from "./CustomersCC";
import DocumentsCC from "./DocumentsCC";
import ProfileCC from "./ProfileCC";
import PaymentsCC from "./PaymentsCC";
import ProcessesCC from "./ProcessesCC";

type MenuItem = Required<MenuProps>['items'][number];

const CustomerCare = () => {
    const {t} = useContext(LocalizationContext)
    const location = useLocation();

    const items: MenuItem[] = [
        {
            key: ROUTE_CC_VEHICLES,
            label: <Link to={ROUTE_CC_VEHICLES}>{t("route." + ROUTE_CC_VEHICLES)}</Link>,
        }, {
            key: ROUTE_CC_DOCUMENTS,
            label: <Link to={ROUTE_CC_DOCUMENTS}>{t("route." + ROUTE_CC_DOCUMENTS)}</Link>,
        }, {
            key: ROUTE_CC_PAYMENTS,
            label: <Link to={ROUTE_CC_PAYMENTS}>{t("route." + ROUTE_CC_PAYMENTS)}</Link>,
        }, {
            key: ROUTE_CC_CUSTOMERS,
            label: <Link to={ROUTE_CC_CUSTOMERS}>{t("route." + ROUTE_CC_CUSTOMERS)}</Link>,
        }, {
            key: ROUTE_CC_PROCESSES,
            label: <Link to={ROUTE_CC_PROCESSES}>{t("route." + ROUTE_CC_PROCESSES)}</Link>,
        }]
    return (
        <>
            <BreadcrumbsCC/>
            <Row gutter={VIEW_GUTTER}>
                <Col span={6}>
                    <Menu
                        selectedKeys={[location.pathname]}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col span={18}>
                    <Routes>
                        <Route path={ROUTE_CC_VEHICLES.replace(ROUTE_CC, "")} element={<VehiclesCC/>}/>
                        <Route path={ROUTE_CC_DOCUMENTS.replace(ROUTE_CC, "")} element={<DocumentsCC/>}/>
                        <Route path={ROUTE_CC_PAYMENTS.replace(ROUTE_CC, "")} element={<PaymentsCC/>}/>
                        <Route path={ROUTE_CC_CUSTOMERS.replace(ROUTE_CC, "")} element={<CustomersCC/>}/>
                        <Route path={ROUTE_CC_PROFILE.replace(ROUTE_CC, "")} element={<ProfileCC/>}/>
                        <Route path={ROUTE_CC_PROCESSES.replace(ROUTE_CC, "")} element={<ProcessesCC/>}/>
                        <Route path={"/"} element={<HomeCC/>}/>
                    </Routes>
                </Col>
            </Row>
        </>
    )
}

export default CustomerCare