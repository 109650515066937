export interface FaqMock {
    id: string;
    title: string;
    text: string;
    important: boolean;
    action?: string;
    actionRoute?: string;
}

export const faqMocks: FaqMock[] = [
    {
        id: "1",
        title: "Wie kann ich einen Werkstatttermin buchen?",
        text: "Sie können einen Werkstatttermin entweder online über unser Kundenportal oder telefonisch bei Ihrem Serviceberater buchen.",
        important: true,
        action: "Zur Terminvereinbarung",
        actionRoute: "/calendar/new"
    },
    {
        id: "2",
        title: "Was passiert bei einer Inspektion?",
        text: "Bei einer Inspektion werden alle wichtigen Fahrzeugteile überprüft, um die Sicherheit und Funktionsfähigkeit Ihres Fahrzeugs zu gewährleisten.",
        important: false,
    },
    {
        id: "3",
        title: "Wie kann ich meinen Vertrag online einsehen?",
        text: "Melden Sie sich in unserem Kundenportal an und navigieren Sie zu 'Meine Verträge', um alle Vertragsdetails einzusehen.",
        important: true,
        action: "Zu meinen Dokumenten",
        actionRoute: "/documents"
    },
    {
        id: "4",
        title: "Welche Zahlungsmethoden akzeptieren Sie?",
        text: "Wir akzeptieren Barzahlung, EC-Karte, Kreditkarte sowie Online-Zahlungen über unser Kundenportal.",
        important: false,
    },
    {
        id: "5",
        title: "Was ist in der Garantie abgedeckt?",
        text: "Unsere Garantie deckt alle mechanischen und elektrischen Komponenten ab, ausgenommen Verschleißteile wie Bremsen und Reifen.",
        important: false,
    },
    {
        id: "6",
        title: "Wie kann ich meinen Leasingvertrag verlängern?",
        text: "Kontaktieren Sie bitte rechtzeitig Ihren Ansprechpartner im Autohaus oder nutzen Sie die Verlängerungsoption im Kundenportal.",
        important: false,
    },
    {
        id: "7",
        title: "Was ist der Unterschied zwischen Wartung und Inspektion?",
        text: "Eine Wartung umfasst reguläre Arbeiten wie Ölwechsel, während bei einer Inspektion alle relevanten Bauteile überprüft werden.",
        important: false,
    },
    {
        id: "8",
        title: "Wie lange dauert eine Reparatur in der Werkstatt?",
        text: "Die Dauer einer Reparatur hängt von der Art des Schadens ab. Ihr Serviceberater gibt Ihnen nach einer Diagnose eine genaue Zeitangabe.",
        important: false,
    },
    {
        id: "9",
        title: "Wie erhalte ich eine Ersatzmobilität während der Reparatur?",
        text: "Unser Autohaus bietet Ihnen bei Bedarf ein Ersatzfahrzeug an. Wenden Sie sich hierfür an Ihren Serviceberater.",
        important: true,
        action: "Serviceberater kontaktieren"
    },
    {
        id: "10",
        title: "Kann ich meinen Servicevertrag an ein neues Fahrzeug übertragen?",
        text: "Ja, Serviceverträge können unter bestimmten Bedingungen auf ein neues Fahrzeug übertragen werden. Bitte sprechen Sie Ihren Ansprechpartner an.",
        important: false,
    },
    {
        id: "11",
        title: "Wie oft sollte ich mein Fahrzeug warten lassen?",
        text: "Wir empfehlen eine Wartung alle 12 Monate oder nach 15.000 Kilometern, je nachdem, was zuerst eintritt.",
        important: false,
    },
    {
        id: "12",
        title: "Wie kann ich meinen Termin verschieben oder stornieren?",
        text: "Termine können Sie über das Kundenportal oder telefonisch bei Ihrem Serviceberater bis zu 24 Stunden vor dem Termin verschieben oder stornieren.",
        important: false,
    }
];
