import React, {useContext} from "react";
import {Card, Collapse, CollapseProps, theme} from "antd";

import StrapiContext from "../context/StrapiContext";
import Headline from "../layout/Headline";
import TODOs from "./dashboard/TODOs";
import Highlights from "./dashboard/Highlights";
import Products from "./dashboard/Products";
import Contact from "./dashboard/Contact";
import Breadcrumbs from "../layout/Breadcrumbs";
import {faqMocks} from "../../mock/faq";
import LocalizationContext from "../context/LocalizationContext";
import NavButton from "../layout/NavButton";
import {ROUTE_FAQ} from "../../constant/route";
import RouteTiles from "./dashboard/RouteTiles";
import MessagesMock from "./dashboard/MessagesMock";
import FaqItem from "./FaqItem";

const Dashboard = () => {
    const {t} = useContext(LocalizationContext);
    const {frontendConfig} = useContext(StrapiContext);
    const {token: {colorBgContainer, colorPrimary}} = theme.useToken();

    const faqItems: CollapseProps['items'] = faqMocks.filter(x => x.important).map(faq => ({
        key: faq.id,
        label: faq.title,
        children: <div style={{paddingInlineStart: 24}}><FaqItem faq={faq}/></div>
    }))

    const faqExtra = <NavButton type={"link"} to={ROUTE_FAQ}>{t("dashboard.faqLink")}</NavButton>;

    return (
        <>
            <Breadcrumbs/>
            <div className={"ssys-dashboard"}>
                {frontendConfig.homeTitle &&
                  <Card style={{backgroundColor: colorPrimary}}>
                    <Headline noMargin={true} style={{color: colorBgContainer}}>{frontendConfig.homeTitle}</Headline>
                  </Card>}
                {frontendConfig.showTodos && <TODOs/>}
                <MessagesMock/>
                <Card title={t("dashboard.faq")} extra={faqExtra}>
                    <Collapse bordered={false} items={faqItems}/>
                </Card>
                <RouteTiles/>
                <Highlights/>
                <Products/>
                <Contact/>
            </div>
        </>

    )
}

export default Dashboard;