import dashboard1 from "../../../assets/dashboard1.png";
import dashboard2 from "../../../assets/dashboard2.png";
import dashboard3 from "../../../assets/dashboard3.png";
import dashboard4 from "../../../assets/dashboard4.png";
import dashboard5 from "../../../assets/dashboard5.png";
import dashboard6 from "../../../assets/dashboard6.png";
import dashboard7 from "../../../assets/dashboard7.png";
import dashboard8 from "../../../assets/dashboard8.png";

import {Col, Row} from "antd";

const HomeCC = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={12} md={6}><img src={dashboard1} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard2} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard3} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard4} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard5} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard6} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard7} style={{width: "100%"}} alt={""}/></Col>
            <Col span={12} md={6}><img src={dashboard8} style={{width: "100%"}} alt={""}/></Col>
        </Row>
    )
}

export default HomeCC;