export interface MessageMock {
    id: string,
    subject: string,
    sender: string,
    read: boolean,
    date: string,
    text: string,
    preCheckIn?: boolean,
    file?: string,
}

export const messageMocks: MessageMock[] = [
    {
        id: "12",
        subject: "Pre-Check-In für Ihren EQB zum Service am 16.10.2024",
        sender: "Alexander.Muster@schmidmaier.de",
        read: true,
        date: "2024-10-08",
        preCheckIn: true,
        text: `Sehr geehrte Frau Huber,\n\nDer Pre-Check-In für Ihren Service Termin am 16.10.2014 um 17.00Uhr ist jetzt für Sie verfügbar. Checken Sie Ihr Fahrzeug mit allen relevanten Unterlagen bereits vorab digital ein und sparen sich die Zeit der Übergabe. Die Übergabe des Schlüssels am Service Desk oder über den Schlüssel-Briefkasten genügt und wir kümmern uns um alles weitere.\n\nViele Grüße\n\nAlexander Muster`
    },
    {
        id: "11",
        subject: "Ihr Service Termin für Ihren EQB am 16.10.2024 ist bestätigt",
        sender: "Alexander.Muster@schmidmaier.de",
        read: true,
        date: "2024-08-22",
        text: `Sehr geehrte Frau Huber,\n\nhiermit Bestätigen wir den Service Termin für Ihren Mercedes Benz EQB am 16.10.2024 um 17.00Uhr. Eine Woche vor dem Termin erhalten Sie weitere Informationen zu einem digitalen Pre-Check-In. Mit dem digitalen Pre-Check-In können Sie alle wichtigen Unterlagen für Ihren Termin schon bequem vorab von zuhause hochladen und das Fahrzeug sowie den Schlüssel am Tag des Service Termins einfach am Service Desk abgeben.\n\nViele Grüße\n\nAlexander Muster`
    },
    {
        id: "10",
        subject: "Ihr neuer Mercedes Benz EQB steht zur Abholung für Sie bereit",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-03-01",
        text: `Sehr geehrte Frau Huber,\n\nWir freuen uns Ihnen Ihr neues Fahrzeug bereits am 1. März 2024 ab 9.00Uhr übergeben zu dürfen.\n\nBitte bestätigen Sie uns den Termin schnell und einfach über folgenden Link.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "9",
        subject: "Ihr Fahrzeugschein für Ihren neuen Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-02-29",
        file: "Foto_Fahrzeugschein_EQB_BB_SC_68E.jpg",
        text: `Sehr geehrte Frau Huber,\n\nanbei erhalten Sie eine digitale Kopie Ihres Fahrzeugscheins für Ihren neuen EQB. Den Fahrzeugschein im Original erhalten Sie bei der Fahrzeugübergabe.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "8",
        subject: "Ihre Zulassungsbescheinigung für Ihren Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-02-28",
        file: "Zulassungsbescheinigung-Teil-2.jpg",
        text: `Sehr geehrte Frau Huber,\n\nanbei erhalten Sie die Zulassungsbescheinigung für Ihren neuen EQB.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "7",
        subject: "Bestätigung Ihrer Kfz-Versicherung für Ihren Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-02-08",
        file: "Versicherung_Muster.jpg",
        text: `Sehr geehrte Frau Huber,\n\nanbei erhalten Sie die KfZ-Versicherungsunterlagen für Ihren neuen EQB.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "6",
        subject: "Eingangsbestätigung Ihrer Zahlung für Ihren Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-01-26",
        text: `Sehr geehrte Frau Huber,\n\nHiermit bestätigen wir Ihnen den Zahlungseingang für Ihren neuen EQB.\n\nSie erhalten in den nächsten Tagen weitere Unterlagen zu Ihrem neuen Fahrzeug und den genauen Abholtermin für Ihren EQB.\n\nWir wünschen Ihnen ganz viel Freude und bedanken uns für Ihr Vertrauen.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "5",
        subject: "Unterzeichneter Vertrag Ihres neuen Mercedes Benz EQBs",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-01-22",
        file: "Kaufvertrag_EQB.jpg",
        text: `Sehr geehrte Frau Huber,\n\nAnbei erhalten Sie die unterschriebenen Vertragsunterlagen für Ihre Unterlagen.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "4",
        subject: "Ihr Vertrag zu Ihrem neuen Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-01-17",
        file: "Kaufvertrag_EQB.jpg",
        text: `Sehr geehrte Frau Huber,\n\nAnbei senden wir Ihnen die Vertragsunterlagen zu Ihrem neuen EQB.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "15",
        subject: "Re: Das Finanzierungsangebot zu Ihrem Mercedes Benz EQB liegt vor",
        sender: "Sina.Huber@gmail.com",
        read: true,
        date: "2024-01-12",
        text: `Sehr geehrte Frau Huber,\n\nanbei senden wir Ihnen das Finanzierungsangebot zu Ihrem EQB.\n\nBei Fragen zum Finanzierungsgebot stehen wir sehr gerne zur Verfügung und beraten Sie gerne bei weiteren Wünschen.\n\nViele Grüße\nMia Müller`
    },
    {
        id: "3",
        subject: "Finanzierungsangebot zu Ihrem neuen Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-01-12",
        file: "Finanzierung_MB_EQB_Muster.pdf",
        text: `Sehr geehrte Frau Huber,\n\nanbei senden wir Ihnen das Finanzierungsangebot zu Ihrem EQB.\n\nBei Fragen zum Finanzierungsgebot stehen wir sehr gerne zur Verfügung und beraten Sie gerne bei weiteren Wünschen.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "14",
        subject: "Re: Das Angebot zu Ihrem Mercedes Benz EQB liegt vor",
        sender: "Sina.Huber@gmail.com",
        read: true,
        date: "2024-01-11",
        text: `Sehr geehrte Frau Huber,\n\nanbei senden wir Ihnen das Angebot zu dem gemeinsam mit Ihnen konfigurierten EQB.\n\nBei Fragen zum Angebot stehen wir sehr gerne zur Verfügung und beraten Sie gerne bei weiteren Wünschen.\n\nViele Grüße\nMia Müller`
    },
    {
        id: "2",
        subject: "Ihr Angebot zu Ihrer Konfiguration MWANSTE7 – Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-01-11",
        file: "Angebot_MB_EQB_Muster.pdf",
        text: `Sehr geehrte Frau Huber,\n\nanbei senden wir Ihnen das Angebot zu dem gemeinsam mit Ihnen konfigurierten EQB.\n\nBei Fragen zum Angebot stehen wir sehr gerne zur Verfügung und beraten Sie gerne bei weiteren Wünschen.\n\nViele Grüße\n\nMia Müller`
    },
    {
        id: "13",
        subject: "Re: Die Konfiguration Ihres Mercedes Benz EQBs steht ab sofort zum Abruf für Sie bereit",
        sender: "Sina.Huber@gmail.com",
        read: true,
        date: "2024-01-08",
        text: `Sehr geehrte Frau Huber,\n\nanbei senden wir Ihnen die gemeinsam mit Ihnen erstellte Konfiguration Ihres neuen EQBs zu.\nSchauen Sie sich die Konfiguration gerne in Ruhe an und geben Sie uns Feedback, falls Sie weitere Konfigurationswünsche haben.\n\nNach Bestätigung Ihrer Konfiguration, erstellen wir gerne ein Angebot für Sie.\n\nViele Grüße \nMia Müller`
    },
    {
        id: "1",
        subject: "Ihre Konfiguration MWANSTE7 zu einem neuen Mercedes Benz EQB",
        sender: "Mia.Mueller@schmidmaier.de",
        read: true,
        date: "2024-01-08",
        file: "Konfiguration_EQB_250+_MWANSTE7.pdf",
        text: `Sehr geehrte Frau Huber,\n\nanbei senden wir Ihnen die gemeinsam mit Ihnen erstellte Konfiguration Ihres neuen EQBs zu.\nSchauen Sie sich die Konfiguration gerne in Ruhe an und geben Sie uns Feedback, falls Sie weitere Konfigurationswünsche haben.\n\nNach Bestätigung Ihrer Konfiguration, erstellen wir gerne ein Angebot für Sie.\n\nViele Grüße\n\nMia Müller`
    }
];

