import {LanguageCode} from "../type/StrapiCustomer";
import {
    ROUTE_ACCOUNT,
    ROUTE_CALENDAR,
    ROUTE_CALENDAR_CREATE,
    ROUTE_CART,
    ROUTE_CC,
    ROUTE_CC_CUSTOMERS,
    ROUTE_CC_DOCUMENTS,
    ROUTE_CC_PAYMENTS,
    ROUTE_CC_PROCESSES,
    ROUTE_CC_PROFILE,
    ROUTE_CC_VEHICLES,
    ROUTE_CONTACT,
    ROUTE_DASHBOARD,
    ROUTE_DOCUMENTS,
    ROUTE_FAQ,
    ROUTE_INVENTORY,
    ROUTE_INVENTORY_CONFIGURE,
    ROUTE_MESSAGES,
    ROUTE_ORDERS,
    ROUTE_PAYMENT,
    ROUTE_PRE_CHECKIN,
    ROUTE_PRICE,
    ROUTE_PRODUCTS,
    ROUTE_SERVICE,
    ROUTE_VEHICLES,
    ROUTE_WISHLIST
} from "./route";
import {FuelType, Transmission} from "../type/StrapiInventory";
import {PaymentType, Status} from "../type/StrapiConfiguration";

const localeEN = {
    footer: {
        menu1: "Menu",
        menu2: "Your Options",
        menu3: "Customer Service"
    },
    route: {
        [ROUTE_DASHBOARD]: "Dashboard",
        [ROUTE_VEHICLES]: "My Vehicles",
        [ROUTE_PRODUCTS]: "Matching Products",
        [ROUTE_DOCUMENTS]: "Documents",
        [ROUTE_CALENDAR]: "My Calendar",
        [ROUTE_PRICE]: "(Car Price)",
        [ROUTE_CONTACT]: "(Your Contact)",
        [ROUTE_INVENTORY]: "Inventory",
        [ROUTE_INVENTORY]: "Inventory",
        [ROUTE_WISHLIST]: "Wishlist",
        [ROUTE_CART]: "Cart",
        [ROUTE_INVENTORY_CONFIGURE]: "Configure",
        [ROUTE_ORDERS]: "My orders",
        [ROUTE_ACCOUNT]: "My Account",
        [ROUTE_FAQ]: "FAQ",
        [ROUTE_PAYMENT]: "Payment",
        [ROUTE_MESSAGES]: "Messages",
        [ROUTE_CALENDAR_CREATE]: "Make an appointment",
        [ROUTE_SERVICE]: "Workshop Inquiry",
        [ROUTE_PRE_CHECKIN]: "Pre-Check-In",
        [ROUTE_CC]: "Dashboard",
        [ROUTE_CC_CUSTOMERS]: "All Customers",
        [ROUTE_CC_VEHICLES]: "All Vehicles",
        [ROUTE_CC_DOCUMENTS]: "All Documents",
        [ROUTE_CC_PAYMENTS]: "All Payments",
        [ROUTE_CC_PROCESSES]: "All Processes",
        [ROUTE_CC_PROFILE]: "My Account",
    },
    transmission: {
        [Transmission.MANUAL]: "Manual",
        [Transmission.AUTOMATIC]: "Automatic"
    },
    fuelType: {
        [FuelType.PETROL]: "Petrol",
        [FuelType.DIESEL]: "Diesel",
        [FuelType.ELECTRIC]: "Electricity"
    },
    status: {
        [Status.PENDING]: "Pending",
        [Status.APPROVED]: "Approved",
        [Status.REJECTED]: "Rejected"
    },
    paymentType: {
        [PaymentType.FINANCING]: "Financing",
        [PaymentType.DIRECT]: "Direct Payment"
    },
    login: {
        email: "Email",
        emailMissing: "Please input your email!",
        password: "Password",
        passwordMissing: "Please input your password!",
        remember: "Remember me",
        submit: "Login",
        error: "Login was not successful"
    },
    dashboard: {
        welcome: "Hello {firstName} {lastName}, welcome back!",
        faq: "You aks - we answer!",
        faqLink: "All entries",
        msgLink: "All Messages",
        routes: {
            headline: "How can we help?",
            calendar: {
                headline: "Workshop appointments",
                action: "All appointments"
            },
            models: {
                headline: "Experience new models",
                text: "Experience the highlights of our vehicles. Simply fill out the form. We look forward to seeing you.",
                action: "Arrange a test drive"
            },
            vehicles: {
                headline: "My vehicles",
                action: "All vehicles"
            },
            documents: {
                headline: "My documents",
                action: "All documents"
            }
        },
        messages: {
            headline: "My Messages.tsx",
            field: {
                sender: "Sender",
                subject: "Subject",
                createdAt: "Date"
            }
        },
        highlights: {
            headline: "Highlights from our stock",
            showAll: "All vehicles in stock"
        },
        products: {
            headline: "Matching products for your vehicles",
            showAll: "All matching products"
        },
        services: {
            headline: "More services for you",
            rent: {
                headline: "Mercedes-Benz Rent",
                text: "Even nicer than changing gears: Changing your car. For a day or a year.",
                action: "Online booking"
            },
            insurance: {
                headline: "Insurance",
                text: "Carefree driving with our high-performance insurance offers for your Mercedes.",
                action: "Compare online"
            },
            subscription: {
                headline: "Car subscription",
                text: "Choose your everyday companion with flexible termination options and all-inclusive package",
                action: "Drive flexibly"
            },
            price: {
                headline: "Determine car prices",
                text: "Simply use the car details to find out the sales price free of charge and receive a quote.",
                action: "Determine vehicle price"
            }
        },
        contact: {
            headline: "Your personal contact"
        }
    },
    category: {
        error: "Could not load category",
        invalidBlockType: "No template implemented for this block type: {type}",
        invalidSlotType: "No template implemented for this slot type: {type}",
    },
    product: {
        error: "Could not load product",
        vehicles: "Matching vehicles",
        description: "Description",
        properties: "Properties",
        vehiclesNone: "You have not yet registered any vehicle matching this product."
    },
    order: {
        error: "Could not load order",
    },
    header: {
        logout: "Logout",
        language: {
            [LanguageCode.DE]: "DE",
            [LanguageCode.EN]: "EN"
        }
    },
    shopware: {
        error: "Shopware Error",
        invalidLanguage: "The selected language does not exist in Shopware: {language}"
    },
    cart: {
        headline: "My Cart",
        empty: "Your cart is still empty",
        products: "Products",
        configurations: "Vehicles"
    },
    wishlist: {
        headline: "My Wishlist",
        empty: "Your wishlist is still empty."
    },
    vehicle: {
        headline: "My Vehicles",
        status: "Status",
        calendar: "Schedule Appointment",
        documents: "Vehicle Documents",
        edit: "Change Vehicle"
    },
    service: {
        headline: "Service Inquiry",
        inquire: "When will my vehicle be ready?"
    },
    contact: {
        headline: "Your Contact"
    },
    price: {
        headline: "Car Price "
    },
    documents: {
        headline: "My Documents",
        type: {
            invoice: "Invoices",
            contracts: "Contracts",
            registrations: "Registrations",
            offers: "Offers",
            certificates: "Certificates"
        },
        open: "Open Document",
        field: {
            topic: "Type",
            title: "Topic",
            vehicle: "Vehicle",
            date: "Date"
        }
    },
    payment: {
        headline: "Payments",
        table: {
            headline: "Your payments and open positions"
        },
        field: {
            incident: "Incident",
            topic: "Topic",
            total: "Total",
            date: "Date"
        }
    },
    calendar: {
        headline: "Calendar",
        empty: "You do not have any calendar entries yet"
    },
    faq: {
        headline: "FAQ"
    },
    products: {
        headline: "Matching products",
        error: "Could not load products",
        vehicle: "Select vehicle",
        search: "Search..."
    },
    productBox: {
        addToCart: "Add to cart",
        detail: "Details"
    },
    orderSubmit: {
        submit: "Create Order"
    },
    inventory: {
        headline: "All vehicles in inventory",
        kilometers: "Mileage:",
        power: "Power:",
        transmission: "Transmission:",
        color: "Color:",
        fuelType: "Fuel type:",
        registration: "Registration:",
        detail: "Show vehicle",
        filter: {
            manufacturer: "Filter manufacturer..."
        },
        configure: "Configure vehicle",
        testDrive: "Request Test Drive"
    },
    configuration: {
        step: {
            1: "Equipment",
            2: "Registration",
            3: "Transfer",
            4: "Payment",
            5: "Summary"
        },
        error: "Error loading configurator data",
        next: "Next",
        prev: "Previous",
        addToCart: "Add to cart",
        products: {
            headline: "Matching Products",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
        },
        registration: {
            headline: "Registration Service",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
            yes: {
                headline: "Registration by dealer",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            },
            no: {
                headline: "Register yourself",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            }
        },
        transfer: {
            headline: "Transfer Service",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
            yes: {
                headline: "Transfer by dealer",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            },
            no: {
                headline: "Pick up yourself",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            }
        },
        payment: {
            headline: "Payment Type",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
            financing: {
                headline: "Financing",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            },
            direct: {
                headline: "Pay immediately",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            }
        },
        summary: {
            headline: "Summary",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
        }
    },
    orders: {
        headline: "My Orders",
        error: "Could not load orders",
        showDetails: "Show Details",
        address: "Address",
        configuration: "Configuration",
        products: "Products",
        price: "Preis:",
        positionsTotal: "Product Total:",
        shippingTotal: "Shipping Total:",
        configurationTotal: "Configuration Total:",
        total: "Total:",
        quantity: "Quantity:"
    },
    account: {
        headline: "My Account",
        address: {
            headline: "My Customer and Contact Information",
            name: "Name",
            mobile: "Mobile",
            phone: "Landline",
            commercial: "Commercial",
            company: "Company",
            street: "Street",
            nr: "House Number",
            zip: "ZIP Code",
            city: "City",
            country: "Country",
            language: "Language"
        },
        login: {
            headline: "My Login Information",
            email: "Email Address",
            changeEmail: "Change Email Address",
            password: "Password",
            changePassword: "Change Password"
        },
        consent: {
            headline: "My Privacy Settings",
            info: "In this section, you can determine how your personal data is used and displayed. Adjust your privacy settings to specify who can see your information and which data we are allowed to store. You retain full control over your data.",
            change: "Adjust Privacy Settings"
        }
    },
    form: {
        model: {
            manufacturer: "Manufacturer"
        },
        vehicle: {
            manufacturer: "Manufacturer",
            model: "Model",
            kilometers: "Kilometers",
            label: "Comment",
            vin: "VIN",
            registration: "Registration",
            number: "License Plate",
            inspection: "Next Inspection",
            status: "Status"
        },
        validation: {
            missing: "{field} is missing!"
        },
        action: {
            cancel: "Cancel",
            create: "Create",
            update: "Update"
        }
    },
    preCheckIn: {
        cta: "Go to pre-check-in",
        available: "Pre-check-in now available"
    }
}

export default localeEN;