import {LanguageCode} from "../type/StrapiCustomer";
import {
    ROUTE_ACCOUNT,
    ROUTE_CALENDAR,
    ROUTE_CALENDAR_CREATE,
    ROUTE_CART,
    ROUTE_CC,
    ROUTE_CC_CUSTOMERS,
    ROUTE_CC_DOCUMENTS,
    ROUTE_CC_PAYMENTS,
    ROUTE_CC_PROCESSES,
    ROUTE_CC_PROFILE,
    ROUTE_CC_VEHICLES,
    ROUTE_CONTACT,
    ROUTE_DASHBOARD,
    ROUTE_DOCUMENTS,
    ROUTE_FAQ,
    ROUTE_INVENTORY,
    ROUTE_INVENTORY_CONFIGURE,
    ROUTE_MESSAGES,
    ROUTE_ORDERS,
    ROUTE_PAYMENT,
    ROUTE_PRE_CHECKIN,
    ROUTE_PRICE,
    ROUTE_PRODUCTS,
    ROUTE_SERVICE,
    ROUTE_VEHICLES,
    ROUTE_WISHLIST
} from "./route";
import {FuelType, Transmission} from "../type/StrapiInventory";
import {PaymentType, Status} from "../type/StrapiConfiguration";

const localeDE = {
    footer: {
        menu1: "Menü",
        menu2: "Ihre Möglichkeiten",
        menu3: "Kundenservice"
    },
    route: {
        [ROUTE_DASHBOARD]: "Dashboard",
        [ROUTE_VEHICLES]: "Meine Fahrzeuge",
        [ROUTE_PRODUCTS]: "Passende Produkte",
        [ROUTE_DOCUMENTS]: "Meine Dokumente",
        [ROUTE_CALENDAR]: "Meine Termine",
        [ROUTE_PRICE]: "(Fahrzeugpreis)",
        [ROUTE_CONTACT]: "(Ihr Kontakt)",
        [ROUTE_INVENTORY]: "Fahrzeugbestand",
        [ROUTE_WISHLIST]: "Wunschzettel",
        [ROUTE_CART]: "Warenkorb",
        [ROUTE_INVENTORY_CONFIGURE]: "Konfiguration",
        [ROUTE_ORDERS]: "Meine Bestellungen",
        [ROUTE_ACCOUNT]: "Mein Account",
        [ROUTE_FAQ]: "FAQ",
        [ROUTE_PAYMENT]: "Meine Zahlungen",
        [ROUTE_MESSAGES]: "Meine Nachrichten",
        [ROUTE_CALENDAR_CREATE]: "Termin vereinbaren",
        [ROUTE_SERVICE]: "Werkstatt-Rückfrage",
        [ROUTE_PRE_CHECKIN]: "Pre-Check-In",
        [ROUTE_CC]: "Dashboard",
        [ROUTE_CC_CUSTOMERS]: "Alle Kunden",
        [ROUTE_CC_VEHICLES]: "Alle Fahrzeuge",
        [ROUTE_CC_DOCUMENTS]: "Alle Dokumente",
        [ROUTE_CC_PAYMENTS]: "Alle Zahlungen",
        [ROUTE_CC_PROCESSES]: "Alle Vorgänge",
        [ROUTE_CC_PROFILE]: "Meine Daten",
    },
    transmission: {
        [Transmission.MANUAL]: "Schaltgetriebe",
        [Transmission.AUTOMATIC]: "Automatik"
    },
    fuelType: {
        [FuelType.PETROL]: "Benzin",
        [FuelType.DIESEL]: "Diesel",
        [FuelType.ELECTRIC]: "Strom"
    },
    status: {
        [Status.PENDING]: "Ausstehend",
        [Status.APPROVED]: "Genehmigt",
        [Status.REJECTED]: "Abgelehnt"
    },
    paymentType: {
        [PaymentType.FINANCING]: "Finanzierung",
        [PaymentType.DIRECT]: "Direkt bezahlen"
    },
    login: {
        email: "Email",
        emailMissing: "Bitte geben Sie eine Email ein!",
        password: "Passwort",
        passwordMissing: "Bitte geben Sie ein Passwort ein!",
        remember: "Login speichern",
        submit: "Login",
        error: "Login war nicht erfolgreich"
    },
    dashboard: {
        welcome: "Hallo {firstName} {lastName}, willkommen zurück!",
        faq: "Sie fragen - wir antworten!",
        faqLink: "Alle Einträge",
        msgLink: "Alle Nachrichten",
        routes: {
            headline: "Wie können wir helfen?",
            calendar: {
                headline: "Werkstatttermine",
                action: "Alle Termine"
            },
            models: {
                headline: "Neue Modelle erleben",
                text: "Erleben Sie die Highlights unserer Fahrzeuge. Füllen Sie einfach das Formular aus. Wir freuen uns auf Sie.",
                action: "Probefahrt vereinbaren"
            },
            vehicles: {
                headline: "Meine Fahrzeuge",
                action: "Alle Fahrzeuge"
            },
            documents: {
                headline: "Meine Dokumente",
                action: "Alle Dokumente"
            }
        },
        messages: {
            headline: "Meine Nachrichten",
            field: {
                sender: "Absender",
                subject: "Betreff",
                createdAt: "Datum"
            }
        },
        highlights: {
            headline: "Aktuelle Fahrzeuge für Sie",
            showAll: "Alle Fahrzeuge"
        },
        products: {
            headline: "Passende Produkte zu Ihren Fahrzeugen",
            showAll: "Alle passenden Produkte"
        },
        services: {
            headline: "Weitere Services für Sie",
            rent: {
                headline: "Mercedes-Benz Rent",
                text: "Noch schöner, als Gänge zu wechseln: Das Auto wechseln. Für einen Tag oder ein Jahr.",
                action: "Online-Buchung"
            },
            insurance: {
                headline: "Versicherungen",
                text: "Sorgenfrei fahren mit unseren leistungsstarken Versicherungsangeboten für Ihren Mercedes.",
                action: "Online vergleichen"
            },
            abo: {
                headline: "Auto Abonnement",
                text: "Wählen Sie Ihren Alltagsbegleiter mit flexiblen Kündigungsoptionen und All-inclusive-Paket",
                action: "Flexibel fahren"
            },
            price: {
                headline: "Kfz-Preise ermitteln",
                text: "Einfach mit den Auto-Details kostenlos Verkaufspreis ermitteln und Angebot erhalten.",
                action: "Fahrzeugpreis ermitteln"
            }
        },
        contact: {
            headline: "Ihr persönlicher Ansprechpartner",
            phone: "Telefon:",
            email: "Email:"
        }
    },
    category: {
        error: "Kategorie konnte nicht geladen werden",
        invalidBlockType: "Für diesen Block-Typ ist kein Template hinterlegt: {type}",
        invalidSlotType: "Für diesen Slot-Typ ist kein Template hinterlegt: {type}",
    },
    product: {
        error: "Produkt konnte nicht geladen werden",
        vehicles: "Passende Fahrzeuge",
        description: "Artikelbeschreibung",
        properties: "Eigenschaften",
        vehiclesNone: "Sie haben keine zu diesem Produkt passenden Fahrzeuge registriert."
    },
    order: {
        error: "Bestellung konnte nicht geladen werden",
    },
    header: {
        logout: "Abmelden",
        language: {
            [LanguageCode.DE]: "DE",
            [LanguageCode.EN]: "EN"
        }
    },
    shopware: {
        error: "Shopware Error",
        invalidLanguage: "Die gewählte Sprache existiert nicht in Shopware: {language}"
    },
    cart: {
        headline: "Mein Warenkorb",
        empty: "Dein Warenkorb ist noch leer",
        products: "Produkte",
        configurations: "Fahrzeuge"
    },
    wishlist: {
        headline: "Mein Merkzettel",
        empty: "Dein Merkzettel ist noch leer"
    },
    vehicle: {
        headline: "Meine Fahrzeuge",
        status: "Status",
        calendar: "Termin vereinbaren",
        documents: "Dokumente zum Fahrzeug",
        edit: "Fahrzeug ändern"
    },
    service: {
        headline: "Werkstatt-Rückfrage",
        inquire: "Wann ist mein Fahrzeug fertig?"
    },
    contact: {
        headline: "Ihr Kontakt"
    },
    price: {
        headline: "Fahrzeugpreis "
    },
    documents: {
        headline: "Meine Dokumente",
        type: {
            invoice: "Rechnungen",
            contracts: "Verträge",
            registrations: "Fahrzeugscheine",
            offers: "Meine Angebote",
            certificates: "Nachweise"
        },
        open: "Dokument öffnen",
        field: {
            topic: "Art",
            title: "Thema",
            vehicle: "Fahrzeug",
            date: "Datum"
        }
    },
    payment: {
        headline: "Meine Zahlungen",
        table: {
            headline: "Zahlungen und offene Posten"
        },
        field: {
            incident: "Vorgang",
            topic: "Thema",
            total: "Betrag",
            date: "Datum"
        }
    },
    calendar: {
        headline: "Meine Termine",
        empty: "Noch keine Einträge vorhanden"
    },
    faq: {
        headline: "FAQ"
    },
    products: {
        headline: "Passende Produkte",
        error: "Produkte konnen nicht geladen werden",
        vehicle: "Wähle ein Fahrzeug aus",
        search: "Suche..."
    },
    productBox: {
        addToCart: "In den Warenkorb",
        detail: "Details"
    },
    orderSubmit: {
        submit: "Bestellung anlegen"
    },
    inventory: {
        headline: "Alle Fahrzeuge im Bestand",
        kilometers: "Kilometerstand:",
        power: "Leistung:",
        transmission: "Getriebe:",
        color: "Farbe:",
        fuelType: "Kraftstoffart:",
        registration: "Erstzulassung",
        detail: "Fahrzeug anzeigen",
        filter: {
            manufacturer: "Nach Hersteller filtern..."
        },
        configure: "Fahrzeug konfigurieren",
        testDrive: "Probefahrt vereinbaren"
    },
    configuration: {
        step: {
            1: "Zubehör",
            2: "Zulassung",
            3: "Transfer",
            4: "Zahlungsart",
            5: "Zusammenfassung"
        },
        error: "Fehler beim Laden der Konfigurator-Daten",
        next: "Weiter",
        prev: "Zurück",
        addToCart: "In den Warenkorb",
        products: {
            headline: "Passendes Zubehör",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
        },
        registration: {
            headline: "Zulassungs-Service",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
            yes: {
                headline: "Zulassung durch Händler",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            },
            no: {
                headline: "Fahrzeug selbst zulassen",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            }
        },
        transfer: {
            headline: "Transfer-Service",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
            yes: {
                headline: "Überführung durch Händler",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            },
            no: {
                headline: "Fahrzeug selbst abholen",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            }
        },
        payment: {
            headline: "Zahlungsart",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
            financing: {
                headline: "Finanzierung",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            },
            direct: {
                headline: "Direkt bezahlen",
                text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
            }
        },
        summary: {
            headline: "Zusammenfassung",
            text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
        }
    },
    orders: {
        headline: "Meine Bestellungen",
        error: "Bestellungen konnten nicht geladen werden",
        showDetails: "Details anzeigen",
        address: "Adresse",
        configuration: "Fahrzeug",
        products: "Produkte",
        price: "Preis",
        positionsTotal: "Produkte",
        shippingTotal: "Versand",
        configurationTotal: "Fahrzeug",
        total: "Gesamt",
        quantity: "Anzahl:"
    },
    account: {
        headline: "Mein Account",
        address: {
            headline: "Meine Kunden- und Kontaktdaten",
            name: "Name",
            mobile: "Mobil",
            phone: "Festnetz",
            commercial: "Gewerblich",
            company: "Firma",
            street: "Straße",
            nr: "Hausnummer",
            zip: "PLZ",
            city: "Ort",
            country: "Land",
            language: "Sprache"
        },
        login: {
            headline: "Meine Zugangsdaten",
            email: "E-Mail-Adresse",
            changeEmail: "Email-Adresse ändern",
            password: "Passwort",
            changePassword: "Passwort ändern"
        },
        consent: {
            headline: "Meine Datenschutzeinstellungen",
            info: "In diesem Bereich können Sie festlegen, wie Ihre persönlichen Daten verwendet und angezeigt werden. Passen Sie Ihre Privatsphäre-Einstellungen an, um zu bestimmen, wer Ihre Informationen sehen kann und welche Daten wir speichern dürfen. Sie behalten die volle Kontrolle über Ihre Daten.",
            change: "Datenschutzeinstellungen anpassen"
        }
    },
    form: {
        model: {
            manufacturer: "Hersteller"
        },
        vehicle: {
            manufacturer: "Hersteller",
            model: "Modell",
            kilometers: "Kilometer",
            label: "Bemerkung",
            vin: "FIN",
            registration: "Erstzulassung",
            number: "Kennzeichen",
            inspection: "Nächste HU",
            status: "Status"
        },
        validation: {
            missing: "{field} wurde nicht ausgefüllt!"
        },
        action: {
            cancel: "Abbrechen",
            create: "Anlegen",
            update: "Aktualisieren"
        }
    },
    preCheckIn: {
        cta: "Zum Pre-Check-In",
        available: "Pre-Check-In jetzt verfügbar"
    }
}

export default localeDE;