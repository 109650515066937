import React, {useContext, useState} from "react";
import StrapiContext from "../../context/StrapiContext";
import {StrapiEntity, StrapiId} from "../../../type/StrapiApi";
import {Button, Card, Row, Space, TableProps} from "antd";
import {compareStrings} from "../../../util/sort";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import {StrapiCustomer} from "../../../type/StrapiCustomer";
import {Col3, Col3double} from "../../layout/Grid";

type CustomerRecord = StrapiCustomer & { id: StrapiId };

const CustomersCC = () => {
    const {allCustomers} = useContext(StrapiContext);

    const [view, setView] = useState<StrapiEntity<StrapiCustomer>>();

    const close = () => {
        setView(undefined)
    }

    const columns: TableProps["columns"] = [{
        title: "Vorname",
        key: "firstName",
        dataIndex: "firstName",
        sorter: compareStrings(x => x.firstName)
    }, {
        title: "Nachname",
        key: "lastName",
        dataIndex: "lastName",
        sorter: compareStrings(x => x.lastName)
    }, {
        title: "Email",
        key: "email",
        dataIndex: "email",
        sorter: compareStrings(x => x.email)
    }, {
        title: "Straße",
        key: "street",
        dataIndex: "street",
        sorter: compareStrings(x => x.street)
    }, {
        title: "PLZ",
        key: "zip",
        dataIndex: "zip",
        sorter: compareStrings(x => x.zip)
    }, {
        title: "Ort",
        key: "city",
        dataIndex: "city",
        sorter: compareStrings(x => x.city)
    },]

    const viewActions = view && (
        <>
            <Button type={"link"} size={"small"} onClick={close}>
                <FontAwesomeIcon icon={faTimes}/>
            </Button>
        </>
    )

    const dataSource = (allCustomers ?? [])
        .map(v => ({id: v.id, ...v.attributes}))

    return (
        <>
            <Card>
                <Table columns={columns} dataSource={dataSource}
                       rowClassName={x => x.id === view?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(vehicle: CustomerRecord) => {
                           return {
                               onClick: () => {
                                   setView(allCustomers.find(v => v.id === vehicle.id))
                               },
                           };
                       }}/>
            </Card>
            {view
                ? (
                    <Card title={view.attributes.firstName + " " + view.attributes.lastName} extra={viewActions}>
                        <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
                            <Row>
                                <Col3><strong>Vorname</strong></Col3>
                                <Col3double>
                                    {view.attributes.firstName}
                                </Col3double>
                            </Row>
                            <Row>
                                <Col3><strong>Nachname</strong></Col3>
                                <Col3double>
                                    {view.attributes.lastName}
                                </Col3double>
                            </Row>
                            <Row>
                                <Col3><strong>Email</strong></Col3>
                                <Col3double>
                                    {view.attributes.email}
                                </Col3double>
                            </Row>
                            <Row>
                                <Col3><strong>Straße</strong></Col3>
                                <Col3double>
                                    {view.attributes.street}
                                </Col3double>
                            </Row>
                            <Row>
                                <Col3><strong>PLZ</strong></Col3>
                                <Col3double>
                                    {view.attributes.zip}
                                </Col3double>
                            </Row>
                            <Row>
                                <Col3><strong>Ort</strong></Col3>
                                <Col3double>
                                    {view.attributes.city}
                                </Col3double>
                            </Row>
                        </Space>
                    </Card>
                )
                : ""}
        </>
    )
}

export default CustomersCC;