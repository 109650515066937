import {Button, Card, Row, Space} from "antd";
import React, {useContext} from "react";
import LocalizationContext from "../../context/LocalizationContext";
import StrapiContext from "../../context/StrapiContext";
import {Col2, Col3, Col3double, Col4} from "../../layout/Grid";

const ProfileCC = () => {
    const {t} = useContext(LocalizationContext);
    const {strapiCustomer} = useContext(StrapiContext)
    return (
        <>
            {strapiCustomer &&
              <Card title={"Meine Daten"}>
                <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
                  <Row>
                    <Col2>
                      <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
                        <Row>
                          <Col3><strong>{t("account.address.name")}</strong></Col3>
                          <Col3double>{strapiCustomer.attributes.firstName} {strapiCustomer.attributes.lastName}</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.mobile")}</strong></Col3>
                          <Col3double>+49 176 1234567</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.phone")}</strong></Col3>
                          <Col3double>12345 67890</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.company")}</strong></Col3>
                          <Col3double>Mustermann & Söhne GmbH</Col3double>
                        </Row>
                      </Space>
                    </Col2>
                    <Col2>
                      <Row>
                        <Col3><strong>{t("account.address.commercial")}</strong></Col3>
                        <Col3double>Ja</Col3double>
                      </Row>
                    </Col2>
                  </Row>
                  <Row>
                    <Col2>
                      <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
                        <Row>
                          <Col3><strong>{t("account.address.street")}</strong></Col3>
                          <Col3double>{strapiCustomer.attributes.street}</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.zip")}</strong></Col3>
                          <Col3double>{strapiCustomer.attributes.zip}</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.country")}</strong></Col3>
                          <Col3double>Deutschland</Col3double>
                        </Row>
                      </Space>
                    </Col2>
                    <Col2>
                      <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
                        <Row>
                          <Col3><strong>{t("account.address.nr")}</strong></Col3>
                          <Col3double>123</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.city")}</strong></Col3>
                          <Col3double>{strapiCustomer.attributes.city}</Col3double>
                        </Row>
                        <Row>
                          <Col3><strong>{t("account.address.language")}</strong></Col3>
                          <Col3double>Deutsch</Col3double>
                        </Row>
                      </Space>
                    </Col2>
                  </Row>
                </Space>
              </Card>}

            {strapiCustomer &&
              <Card title={t("account.login.headline")}>
                <Space direction={"vertical"} size={"small"} style={{width: "100%"}}>
                  <Row>
                    <Col2>
                      <Row>
                        <Col3><strong>{t("account.login.email")}</strong></Col3>
                        <Col3double>{strapiCustomer.attributes.email}</Col3double>
                      </Row>
                    </Col2>
                    <Col4>
                      <Button type="primary" ghost={true} block={true}>
                          {t("account.login.changeEmail")}
                      </Button>
                    </Col4>
                  </Row>
                  <Row>
                    <Col2>
                      <Row>
                        <Col3><strong>{t("account.login.password")}</strong></Col3>
                        <Col3double>**********</Col3double>
                      </Row>
                    </Col2>
                    <Col4>
                      <Button type="primary" ghost={true} block={true}>
                          {t("account.login.changePassword")}
                      </Button>
                    </Col4>
                  </Row>
                </Space>
              </Card>}
        </>
    )
}

export default ProfileCC;