import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {faCaretDown, faCaretUp, faCircleUser, faGlobe, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {faHeart} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, Dropdown, MenuProps, theme} from 'antd';

import {
    ROUTE_ACCOUNT,
    ROUTE_CALENDAR,
    ROUTE_CART,
    ROUTE_CC,
    ROUTE_CC_PROFILE,
    ROUTE_DOCUMENTS,
    ROUTE_MESSAGES,
    ROUTE_VEHICLES,
    ROUTE_WISHLIST
} from "../../constant/route";
import ShopwareContext from "../context/ShopwareContext";
import StrapiContext from "../context/StrapiContext";
import LocalizationContext from "../context/LocalizationContext";
import {LanguageCode} from "../../type/StrapiCustomer";
import {getMediaUrl} from "../../type/StrapiApi";
import ThemeContext from "../context/ThemeContext";
import "./Header.scss";

interface Props {
    ccMode: boolean
}

const Header = ({ccMode}: Props) => {
    const {strapiCustomer, logout, language, setLanguage, cartQuantity, frontendConfig} = useContext(StrapiContext);
    const {wishlistQuantity} = useContext(ShopwareContext);
    const {t} = useContext(LocalizationContext);
    const {logoHeight} = useContext(ThemeContext);

    const {token: {Layout, colorPrimary}} = theme.useToken();

    const [userOpen, setUserOpen] = useState<boolean>();
    const [languageOpen, setLanguageOpen] = useState<boolean>();

    const userMenu: MenuProps['items'] = [{
        key: 'account',
        label: <Link
            to={ccMode ? ROUTE_CC_PROFILE : ROUTE_ACCOUNT}>{t("route." + (ccMode ? ROUTE_CC_PROFILE : ROUTE_ACCOUNT))}</Link>
    }, {
        key: 'logout',
        label: <span onClick={logout}>{t("header.logout")}</span>
    }];

    const languageMenu: MenuProps['items'] = Object.values(LanguageCode).map(language => {
        return {
            key: language,
            label: <span onClick={() => setLanguage(language)}>{t("header.language." + language)}</span>
        }
    });

    const logo = frontendConfig.headerLogo?.data;

    return (
        <div style={{display: "flex", flexDirection: "column", gap: ".75rem"}}>
            <div style={{display: "flex", overflow: "hidden", alignItems: "center", paddingTop: ".5rem"}}>
                {logo &&
                  <img src={getMediaUrl(logo)} alt={logo.attributes.alternativeText}
                       style={{height: logoHeight ?? "1rem", cursor: "pointer", marginRight: "1rem"}}
                       onClick={() => window.location.hash = ccMode ? ROUTE_CC : ""}/>}
                <div style={{flex: 1}}/>
                {strapiCustomer &&
                  <div style={{display: "flex", whiteSpace: "nowrap"}}>
                      {!ccMode &&
                        <div style={{marginRight: "1rem"}}>
                          <Badge count={wishlistQuantity} size={"small"} color={colorPrimary}
                                 style={{boxShadow: "none", pointerEvents: "none"}}>
                            <Link to={ROUTE_WISHLIST}>
                              <FontAwesomeIcon icon={faHeart} style={{color: Layout?.headerColor}}/>
                            </Link>
                          </Badge>
                        </div>}
                      {!ccMode &&
                        <div style={{marginRight: "1.5rem"}}>
                          <Badge count={cartQuantity} size={"small"} color={colorPrimary}
                                 style={{boxShadow: "none", pointerEvents: "none"}}>
                            <Link to={ROUTE_CART}>
                              <FontAwesomeIcon icon={faShoppingCart} style={{color: Layout?.headerColor}}/>
                            </Link>
                          </Badge>
                        </div>}
                    <Dropdown menu={{items: userMenu}} placement="bottomRight" trigger={["click"]}
                              open={userOpen} onOpenChange={setUserOpen}>
                      <div style={{cursor: "pointer", marginRight: "1.5rem"}}>
                        <FontAwesomeIcon icon={faCircleUser} style={{marginRight: ".5rem"}}/>
                        <span>
                                {strapiCustomer.attributes.firstName.substring(0, 1)}. {strapiCustomer.attributes.lastName}
                            </span>
                        <FontAwesomeIcon icon={userOpen ? faCaretUp : faCaretDown}
                                         style={{marginLeft: ".5rem"}}/>
                      </div>
                    </Dropdown>
                    <Dropdown menu={{items: languageMenu}} placement="bottomRight" trigger={["click"]}
                              open={languageOpen} onOpenChange={setLanguageOpen}>
                      <div style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faGlobe} style={{marginRight: ".5rem"}}/>
                        <span>{t("header.language." + language)}</span>
                        <FontAwesomeIcon icon={languageOpen ? faCaretUp : faCaretDown}
                                         style={{marginLeft: ".5rem"}}/>
                      </div>
                    </Dropdown>
                  </div>}
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    {ccMode && <strong>Customer Care Center</strong>}
                </div>
                <div style={{display: "flex", gap: "1rem", justifyContent: "flex-end"}}>
                    {ccMode ? (
                        <>
                            <Link to={ROUTE_CC_PROFILE} style={{color: Layout?.headerColor}}>Meine Daten</Link>
                        </>
                    ) : (
                        <>
                            <Link to={ROUTE_VEHICLES}
                                  style={{color: Layout?.headerColor}}>{t("route." + ROUTE_VEHICLES)}</Link>
                            <Link to={ROUTE_DOCUMENTS}
                                  style={{color: Layout?.headerColor}}>{t("route." + ROUTE_DOCUMENTS)}</Link>
                            <Link to={ROUTE_MESSAGES}
                                  style={{color: Layout?.headerColor}}>{t("route." + ROUTE_MESSAGES)}</Link>
                            <Link to={ROUTE_CALENDAR}
                                  style={{color: Layout?.headerColor}}>{t("route." + ROUTE_CALENDAR)}</Link>
                        </>
                    )}

                </div>
            </div>

        </div>
    );
};

export default Header;