import React, {PureComponent, type ReactNode} from 'react';
import Strapi, {StrapiError, StrapiRequestParams} from "strapi-sdk-js";
import {Order, Product} from "@shopware-pwa/types";

import StrapiContext from "./StrapiContext";
import {LanguageCode, StrapiCustomer} from "../../type/StrapiCustomer";
import {
    STRAPI_TYPE_CONFIGURATION,
    STRAPI_TYPE_CUSTOMER,
    STRAPI_TYPE_FRONTEND_CONFIG,
    STRAPI_TYPE_INVENTORY,
    STRAPI_TYPE_MANUFACTURER,
    STRAPI_TYPE_MODEL,
    STRAPI_TYPE_ORDERS,
    STRAPI_TYPE_SHOPWARE_CONFIG,
    STRAPI_TYPE_VEHICLE
} from "../../constant/strapi";
import {StrapiEntity, StrapiId} from "../../type/StrapiApi";
import {notifyError} from "../../util/event";
import {StrapiVehicle, StrapiVehicleForm} from "../../type/StrapiVehicle";
import {StrapiManufacturer} from "../../type/StrapiManufacturer";
import {StrapiModel} from "../../type/StrapiModel";
import {StrapiInventory} from "../../type/StrapiInventory";
import {Status, StrapiConfiguration, StrapiConfigurationForm} from "../../type/StrapiConfiguration";
import {StrapiOrder} from "../../type/StrapiOrder";
import {StrapiJsonLineItem} from "../../type/StrapiLineItem";
import {DEFAULT_SHOPWARE_CONFIG, ShopwareConfig} from "../../type/ShopwareConfig";
import {DEFAULT_FRONTEND_CONFIG, FrontendConfig} from "../../type/FontendConfig";

interface Props {
    children: ReactNode
}

const CUSTOMER_PARAMS = {
    populate: ["contact.photo", "messages", "calendars"]
};

interface State {
    customer?: StrapiEntity<StrapiCustomer>,
    allCustomers: StrapiEntity<StrapiCustomer>[],
    vehicles: StrapiEntity<StrapiVehicle>[],
    allVehicles: StrapiEntity<StrapiVehicle>[],
    inventory: StrapiEntity<StrapiInventory>[],
    manufacturers: StrapiEntity<StrapiManufacturer>[],
    configurations: StrapiEntity<StrapiConfiguration>[],
    loginError?: boolean,
    shopwareConfig: ShopwareConfig,
    frontendConfig: FrontendConfig
}

class StrapiProvider extends PureComponent<Props, State> {

    private readonly strapiClient: Strapi;

    constructor(props: Props) {
        super(props);

        this.state = {
            allCustomers: [],
            vehicles: [],
            allVehicles: [],
            inventory: [],
            manufacturers: [],
            configurations: [],
            shopwareConfig: DEFAULT_SHOPWARE_CONFIG,
            frontendConfig: DEFAULT_FRONTEND_CONFIG
        }

        this.strapiClient = new Strapi({
            url: process.env.REACT_APP_STRAPI_URL,
            axiosOptions: {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN_WRITE}`
                }
            }
        })
    }

    componentDidMount() {
        this.strapiClient.find<StrapiEntity<ShopwareConfig>>(STRAPI_TYPE_SHOPWARE_CONFIG)
            .then(result => this.setState({shopwareConfig: result.data.attributes}))
            .catch((response: StrapiError) => this._handleError(response));

        this.strapiClient.find<StrapiEntity<FrontendConfig>>(STRAPI_TYPE_FRONTEND_CONFIG, {
            populate: ["headerLogo", "dashboardCalendarImage", "dashboardModelImage", "dashboardVehicleImage"]
        })
            .then(result => this.setState({frontendConfig: result.data.attributes}))
            .catch((response: StrapiError) => this._handleError(response));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (!prevState.customer && this.state.customer) this._loadCustomerData();
    }

    _loadCustomerData = () => {
        this._loadVehicles();
        this._loadAllVehicles();
        this._loadConfigurations();
        this._loadManufacturers();
        this._loadInventory();
        this._loadAllCustomers();
    }

    _loadAllCustomers = () => {
        this.strapiClient.find<StrapiEntity<StrapiCustomer>[]>(STRAPI_TYPE_CUSTOMER, {
        })
            .then(result => this.setState({allCustomers: result.data}))
            .catch(error => this._handleError(error))
    }

    _loadVehicles = () => {
        if (!this.state.customer) return;

        this.strapiClient.find<StrapiEntity<StrapiVehicle>[]>(STRAPI_TYPE_VEHICLE, {
            filters: {customer: {id: this.state.customer.id}},
            sort: "registration:desc",
            populate: ["model.manufacturer.logo", "model.image"]
        })
            .then(result => this.setState({vehicles: result.data}))
            .catch(error => this._handleError(error))
    }

    _loadAllVehicles = () => {
        this.strapiClient.find<StrapiEntity<StrapiVehicle>[]>(STRAPI_TYPE_VEHICLE, {
            sort: "registration:desc",
            populate: ["model.manufacturer.logo", "model.image"]
        })
            .then(result => this.setState({allVehicles: result.data}))
            .catch(error => this._handleError(error))
    }

    _loadConfigurations = () => {
        if (!this.state.customer) return;

        this.strapiClient.find<StrapiEntity<StrapiConfiguration>[]>(STRAPI_TYPE_CONFIGURATION, {
            filters: {customer: {id: this.state.customer.id}, order: {id: {$null: true}}},
            sort: "inventory.label:asc",
            populate: ["inventory.images", "inventory.model.manufacturer"]
        })
            .then(result => this.setState({configurations: result.data}))
            .catch(error => this._handleError(error))
    }

    _loadInventory = () => {
        this.strapiClient.find<StrapiEntity<StrapiInventory>[]>(STRAPI_TYPE_INVENTORY, {
            sort: "label:asc",
            populate: ["images", "model.manufacturer"]
        })
            .then(result => this.setState({inventory: result.data}))
            .catch(error => this._handleError(error))
    }

    getInventory = (id: StrapiId) => {
        return new Promise<StrapiEntity<StrapiInventory>>((resolve, reject) => {
            this.strapiClient.findOne<StrapiEntity<StrapiInventory>>(STRAPI_TYPE_INVENTORY, id, {
                populate: ["images", "model.manufacturer.logo"]
            })
                .then(result => resolve(result.data))
                .catch(error => reject(error))
        })
    }

    _loadManufacturers = () => {
        this.strapiClient.find<StrapiEntity<StrapiManufacturer>[]>(STRAPI_TYPE_MANUFACTURER)
            .then(result => this.setState({manufacturers: result.data}))
            .catch(error => this._handleError(error))
    }

    getModels = (manufacturerId?: StrapiId) => {
        const params: StrapiRequestParams = {};
        if (manufacturerId) {
            params.filters = {manufacturer: {id: manufacturerId}};
        }

        return new Promise<StrapiEntity<StrapiModel>[]>(resolve => {
            this.strapiClient.find<StrapiEntity<StrapiModel>[]>(STRAPI_TYPE_MODEL, params)
                .then(result => resolve(result.data))
                .catch(error => {
                    this._handleError(error)
                    resolve([])
                })
        })
    }

    getOrders = () => {
        return new Promise<StrapiEntity<StrapiOrder>[]>(resolve => {
            if (!this.state.customer) resolve([]);
            else this.strapiClient.find<StrapiEntity<StrapiOrder>[]>(STRAPI_TYPE_ORDERS, {
                filters: {customer: {id: this.state.customer.id}},
                sort: "createdAt:desc",
                populate: ["configuration.inventory.images", "configuration.inventory.model.manufacturer"]
            })
                .then(result => resolve(result.data))
                .catch(error => {
                    this._handleError(error)
                    resolve([])
                })
        })
    }

    login = (email: string, password: string) => {
        this.strapiClient.find<StrapiEntity<StrapiCustomer>[]>(STRAPI_TYPE_CUSTOMER, {
            filters: {email: email, password: password, active: true},
            ...CUSTOMER_PARAMS
        })
            .then(customers => {
                if (customers.data.length === 1) {
                    this.setState({customer: customers.data[0], loginError: false})
                } else {
                    this.setState({customer: undefined, loginError: true})
                }
            })
    }

    logout = () => {
        this.setState({customer: undefined})
    }

    setLanguage = (language: LanguageCode) => {
        this.updateCustomer({language: language})
    }

    createVehicle = (data: StrapiVehicleForm) => {
        return new Promise<void>((resolve, reject) => {
            this.strapiClient.create<StrapiEntity<StrapiVehicle>>(STRAPI_TYPE_VEHICLE, {
                customer: {id: this.state.customer!.id},
                number: data.number,
                model: {id: data.modelId},
                kilometers: data.kilometers,
                label: data.label,
                vin: data.vin,
                registration: data.registrationDate.format("YYYY-MM-DD"),
                inspection: data.inspectionDate ? data.inspectionDate.format("YYYY-MM-DD") : undefined,
            })
                .then(() => {
                    this._loadVehicles();
                    this._loadAllVehicles();
                    resolve()
                })
                .catch((response: StrapiError) => reject(response.error))
        })
    }

    updateVehicle = (id: StrapiId, data: StrapiVehicleForm) => {
        return new Promise<void>((resolve, reject) => {
            this.strapiClient.update<StrapiEntity<StrapiVehicle>>(STRAPI_TYPE_VEHICLE,
                id, {
                    number: data.number,
                    model: {id: data.modelId},
                    kilometers: data.kilometers,
                    label: data.label,
                    vin: data.vin,
                    registration: data.registrationDate.format("YYYY-MM-DD"),
                    inspection: data.inspectionDate ? data.inspectionDate.format("YYYY-MM-DD") : undefined,
                })
                .then(() => {
                    this._loadVehicles();
                    this._loadAllVehicles();
                    resolve()
                })
                .catch((response: StrapiError) => reject(response.error))
        })
    }

    updateCustomer = (data: Partial<StrapiCustomer>) => {
        if (!this.state.customer) return;

        this.strapiClient.update<StrapiEntity<StrapiCustomer>>(STRAPI_TYPE_CUSTOMER, this.state.customer.id, data, {
            ...CUSTOMER_PARAMS
        })
            .then(response => this.setState({customer: {...response.data}}))
    }

    saveConfiguration = (config: StrapiConfigurationForm) => {
        return new Promise<void>((resolve, reject) => {
            if (!this.state.customer) reject();
            else this.strapiClient.create<StrapiEntity<StrapiConfiguration>>(STRAPI_TYPE_CONFIGURATION, {
                inventory: {id: config.inventoryId},
                customer: {id: this.state.customer.id},
                paymentType: config.paymentType,
                status: Status.PENDING,
                registrationService: config.registrationService,
                transferService: config.transferService,
                cart: config.cart
            })
                .then(() => {
                    this._loadConfigurations();
                    resolve()
                })
                .catch((response: StrapiError) => reject(response.error))
        })
    }

    removeConfiguration = (id: StrapiId) => {
        this.strapiClient.delete<StrapiEntity<StrapiConfiguration>>(STRAPI_TYPE_CONFIGURATION, id)
            .then(() => {
                this._loadConfigurations();
            })
            .catch((error: StrapiError) => this._handleError(error))
    }

    createOrder = (shopwareOrderId?: Order["id"], configurationId?: StrapiId) => {
        return new Promise<void>((resolve, reject) => {
            if (!this.state.customer) reject();
            else {
                this.strapiClient.create<StrapiEntity<StrapiOrder>>(STRAPI_TYPE_ORDERS, {
                    customer: {id: this.state.customer.id},
                    configuration: configurationId ? {id: configurationId} : undefined,
                    shopwareOrderId: shopwareOrderId
                })
                    .then(() => {
                        this._loadConfigurations();
                        resolve()
                    })
                    .catch((response: StrapiError) => reject(response.error))
            }
        })
    }

    _handleError = (response: StrapiError) => {
        notifyError({
            title: response.error.name + " (" + response.error.status + ")",
            message: response.error.message
        })
    }

    _updateCart = (cart: StrapiJsonLineItem[]) => {
        if (!this.state.customer) return;

        this.strapiClient.update<StrapiEntity<StrapiCustomer>>(STRAPI_TYPE_CUSTOMER, this.state.customer.id, {cart: cart}, {
            ...CUSTOMER_PARAMS
        })
            .then(response => this.setState({customer: response.data}))
            .catch(error => this._handleError(error))
    }

    clearCart = (productIds: string[]) => {
        if (!this.state.customer) return;

        this._updateCart(this.state.customer?.attributes.cart
            .filter(l => !productIds.includes(l.shopwareProductId)));
    }

    addToCart = (id: Product["id"], quantity: number = 1) => {
        if (!this.state.customer) return;

        const cart = this.state.customer.attributes.cart ?? [];
        const item = cart.find(c => c.shopwareProductId === id);

        if (!item) {
            cart.push({shopwareProductId: id, quantity: quantity})
        } else {
            this.changeCartQuantity(id, item.quantity + quantity)
        }

        this._updateCart(cart);
    }

    removeCartItem = (id: Product["id"]) => {
        if (!this.state.customer) return;
        const cart = this.state.customer.attributes.cart ?? [];

        this._updateCart(cart.filter(c => c.shopwareProductId !== id));
    }

    changeCartQuantity = (id: Product["id"], quantity: number) => {
        if (!this.state.customer) return;

        const cart = this.state.customer.attributes.cart ?? [];
        this._updateCart(cart.map(c => c.shopwareProductId === id ? {...c, quantity: quantity} : c));
    }

    getAggregatedCart = (): StrapiJsonLineItem[] => {
        if (!this.state.customer) return [];

        const cart = [...this.state.customer.attributes.cart];
        const configurations = this.state.configurations;

        configurations.forEach(cfg => {
            if (cfg.attributes.cart) cfg.attributes.cart.forEach(c => {
                const idx = cart
                    .findIndex(item => item.shopwareProductId === c.shopwareProductId);
                if (idx !== -1) {
                    cart[idx] = {...cart[idx], quantity: cart[idx].quantity + c.quantity}
                } else {
                    cart.push(c);
                }
            })
        })

        return cart;
    }

    _getCartQuantity = () => {
        if (!this.state.customer) return 0;

        const configurations = this.state.configurations;
        const cart = this.state.customer.attributes.cart ?? [];

        let count = cart.reduce((total, item) => {
            return total + item.quantity;
        }, 0);

        configurations.forEach(c => {
            count += 1;
            count += c.attributes.cart ? c.attributes.cart.length : 0
        })

        return count;
    }

    render() {
        return (
            <StrapiContext.Provider value={{
                shopwareConfig: this.state.shopwareConfig,
                frontendConfig: this.state.frontendConfig,

                strapiCustomer: this.state.customer,
                allCustomers: this.state.allCustomers,
                updateStrapiCustomer: this.updateCustomer,
                vehicles: this.state.vehicles,
                allVehicles: this.state.allVehicles,
                inventory: this.state.inventory,
                manufacturers: this.state.manufacturers,
                configurations: this.state.configurations,
                language: this.state.customer ? this.state.customer.attributes.language : LanguageCode.DE,
                login: this.login,
                loginError: this.state.loginError,
                logout: this.logout,
                setLanguage: this.setLanguage,
                getModels: this.getModels,
                createVehicle: this.createVehicle,
                updateVehicle: this.updateVehicle,
                getInventory: this.getInventory,
                getOrders: this.getOrders,
                saveConfiguration: this.saveConfiguration,
                removeConfiguration: this.removeConfiguration,
                createOrder: this.createOrder,

                addToCart: this.addToCart,
                changeCartItemQuantity: this.changeCartQuantity,
                removeCartItem: this.removeCartItem,
                cartQuantity: this._getCartQuantity(),
                getAggregatedCart: this.getAggregatedCart,
                clearCart: this.clearCart,
            }}>
                {this.props.children}
            </StrapiContext.Provider>
        )
    }
}

export default StrapiProvider;