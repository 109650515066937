import React, {useContext, useMemo} from "react";
import {Button, Card, Col, Row, TableProps} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImage, faFilePdf, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import nl2br from "react-nl2br";

import LocalizationContext from "../context/LocalizationContext";
import Headline from "../layout/Headline";
import Breadcrumbs from "../layout/Breadcrumbs";
import Table, {TABLE_ROW_BOLD} from "../shared/Table";
import Date from "../shared/Date";
import {MessageMock, messageMocks} from "../../mock/messages";
import {ROUTE_MESSAGE, ROUTE_MESSAGES, ROUTE_PRE_CHECKIN} from "../../constant/route";
import {compareStrings} from "../../util/sort";

const Documents = () => {
    const {t} = useContext(LocalizationContext);
    const navigate = useNavigate();
    const params = useParams<'id'>();

    const message: MessageMock | undefined = useMemo(() => {
        return params.id ? messageMocks.find(m => m.id === params.id) : undefined
    }, [params.id])

    const columns: TableProps["columns"] = [{
        title: t("dashboard.messages.field.sender"),
        key: "sender",
        dataIndex: "sender",
        sorter: compareStrings(x => x.sender)
    }, {
        title: t("dashboard.messages.field.subject"),
        key: "subject",
        dataIndex: "subject",
        sorter: compareStrings(x => x.subject)
    }, {
        title: t("dashboard.messages.field.createdAt"),
        key: "date",
        dataIndex: "date",
        align: "right",
        sorter: compareStrings(x => x.date),
        render: (createdAt: string) => <Date value={createdAt}/>
    }]

    const open = (messageId?: string) => messageId
        ? navigate(ROUTE_MESSAGE.replace(":id", messageId))
        : navigate(ROUTE_MESSAGES)

    const extra = (
        <Button type={"link"} size={"small"} onClick={() => open(undefined)}>
            <FontAwesomeIcon icon={faTimes}/>
        </Button>
    )

    return (
        <>
            <Breadcrumbs/>
            <Headline>{t("dashboard.messages.headline")}</Headline>
            <Card>
                <Table columns={columns} dataSource={messageMocks}
                       rowClassName={x => x.id === message?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(m) => {
                           return {onClick: () => open(m.id)};
                       }}/>
            </Card>
            {message &&
              <Card title={message.subject} extra={extra}>
                <Row>
                  <Col span={message.file ? 18 : 24}>
                      {nl2br(message.text)}
                      {message.preCheckIn &&
                        <div style={{marginTop: "1rem"}}>
                          <Button type={"primary"} href={"#" + ROUTE_PRE_CHECKIN}>{t("preCheckIn.cta")}</Button></div>}
                  </Col>
                    {message.file && <Col span={6}>
                      <div style={{marginBottom: "2rem"}}>
                          {message.file.includes(".pdf")
                              ? <FontAwesomeIcon icon={faFilePdf} size={"6x"}/>
                              : <FontAwesomeIcon icon={faFileImage} size={"6x"}/>}
                        <p><strong>{message.file}</strong></p>
                      </div>
                      <Button type={"primary"} ghost={true} block={true} href={"./" + message.file}
                              target={"_blank"}>
                          {t("documents.open")}
                      </Button>
                    </Col>}
                </Row>
              </Card>}
        </>
    )
}

export default Documents;