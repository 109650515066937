export interface DocumentMock {
    id: string,
    topic: string,
    title: string,
    important: boolean,
    date: string,
    file: string,
}

export const documentMocks: DocumentMock[] = [
    {
        id: "10",
        topic: "Übergabeprotokoll",
        title: "Protokoll Übergabe Mercedes Benz EQB",
        important: false,
        date: "2024-03-01",
        file: "Protokoll-zur-Fahrzeuguebergabe.webp"
    },
    {
        id: "9",
        topic: "Fahrzeugpapiere",
        title: "Fahrzeugschein Mercedes Benz EQB",
        important: false,
        date: "2024-02-29",
        file: "Foto_Fahrzeugschein_EQB_BB_SC_68E.jpg"
    },
    {
        id: "8",
        topic: "Fahrzeugpapiere",
        title: "Zulassungsbescheinigung Mercedes Benz EQB",
        important: false,
        date: "2024-02-28",
        file: "Zulassungsbescheinigung-Teil-2.jpg"
    },
    {
        id: "7",
        topic: "Versicherung",
        title: "Kfz-Versicherung Mercedes Benz EQB",
        important: false,
        date: "2024-02-08",
        file: "Versicherung_Muster.jpg"
    },
    {
        id: "6",
        topic: "Zahlungseingang",
        title: "Eingangsbestätigung Zahlung Mercedes Benz EQB",
        important: false,
        date: "2024-01-26",
        file: "mock.pdf"
    },
    {
        id: "5",
        topic: "Vertrag",
        title: "Unterschriebener Vertrag Mercedes Benz EQB",
        important: false,
        date: "2024-01-22",
        file: "Kaufvertrag_EQB.jpg"
    },
    {
        id: "4",
        topic: "Vertrag",
        title: "Vertragsunterlagen Mercedes Benz EQB",
        important: false,
        date: "2024-01-17",
        file: "Kaufvertrag_EQB.jpg"
    },
    {
        id: "3",
        topic: "Finanzierung",
        title: "Finanzierungsangebot Mercedes Benz EQB",
        important: false,
        date: "2024-01-12",
        file: "Finanzierung_MB_EQB_Muster.pdf"
    },
    {
        id: "2",
        topic: "Angebot",
        title: "Angebot zu Ihrer Konfiguration – Mercedes Benz EQB",
        important: false,
        date: "2024-01-11",
        file: "Angebot_MB_EQB_Muster.pdf"
    },
    {
        id: "1",
        topic: "Konfiguration",
        title: "Ihre Konfiguration zu einem neuen EQB",
        important: false,
        date: "2024-01-08",
        file: "Konfiguration_EQB_250+_MWANSTE7.pdf",
    }
];
