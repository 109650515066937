import {FaqMock} from "../../mock/faq";
import {Button} from "antd";

interface Props {
    faq: FaqMock
}

const FaqItem = ({faq}: Props) => {
    return (
        <>
            <p>{faq.text}</p>
            {faq.action && <Button href={faq.actionRoute ? ("#" + faq.actionRoute) : "#"} type={"primary"}
                                   ghost={true} disabled={!faq.actionRoute}>{faq.action}</Button>}
        </>
    )
}

export default FaqItem;