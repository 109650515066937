import React, {ReactNode, useContext} from "react";
import {Breadcrumb} from "antd";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";

import LocalizationContext from "../context/LocalizationContext";
import {
    ROUTE_DASHBOARD,
    ROUTE_INVENTORY, ROUTE_INVENTORY_CONFIGURE,
    ROUTE_INVENTORY_DETAIL,
    ROUTE_PRODUCT,
    ROUTE_PRODUCTS
} from "../../constant/route";

interface Props {
    route?: string,
    title?: ReactNode,
    id?: string
}

const Breadcrumbs = ({route, title, id}: Props) => {
    const {t} = useContext(LocalizationContext);

    const location = useLocation();
    const resolvedRoute = route || location.pathname;

    const breadcrumbs = [
        {title: <Link to={ROUTE_DASHBOARD}><FontAwesomeIcon icon={faHouse}/></Link>}
    ];

    switch (resolvedRoute) {
        case ROUTE_PRODUCT:
            breadcrumbs.push({
                title: <Link to={ROUTE_PRODUCTS}>{t("route." + ROUTE_PRODUCTS)}</Link>
            })
            break;
        case ROUTE_INVENTORY_DETAIL:
            breadcrumbs.push({
                title: <Link to={ROUTE_INVENTORY}>{t("route." + ROUTE_INVENTORY)}</Link>
            })
            break;
        case ROUTE_INVENTORY_CONFIGURE:
            breadcrumbs.push({
                title: <Link to={ROUTE_INVENTORY}>{t("route." + ROUTE_INVENTORY)}</Link>
            })
            if (id) {
                breadcrumbs.push({
                    title: <Link to={ROUTE_INVENTORY_DETAIL.replace(":id", id)}>{title}</Link>
                })
            }
    }

    const baseRoute = resolvedRoute.includes(":") ? resolvedRoute : resolvedRoute.split('/').slice(0, 2).join('/');

    breadcrumbs.push({
        title: (title && !id) ? <>{title}</> : t("route." + baseRoute)
    })

    return (
        <Breadcrumb style={{margin: '1rem 0'}} items={breadcrumbs}/>
    )
}

export default Breadcrumbs;