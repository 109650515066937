import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Alert, Button, Card, Col, Form, Input, Radio, Row, Select, Steps, TableProps} from "antd";
import {compareStrings} from "../../../util/sort";
import {formatDate} from "../vehicle/util";
import Table, {TABLE_ROW_BOLD} from "../../shared/Table";
import {ProcessMock, ProcessMockHandler, processMocks, ProcessMockStatus} from "../../../mock/process";
import StrapiContext from "../../context/StrapiContext";
import {VehicleDetailLeft} from "../vehicle/VehicleDetail";

interface Props {
    send: () => void;
}

const ProcessForm = ({send}: Props) => {
    const {allVehicles} = useContext(StrapiContext);
    return (
        <>
            <Row>
                <Col span={8}>
                    <VehicleDetailLeft vehicle={allVehicles[0]}/>
                </Col>
                <Col span={16}>
                    <Form
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                    >
                        <Form.Item label="Garantieprodukt" required={true}>
                            <Select defaultValue={"1"}>
                                <Select.Option value="1">Die Hyundai kleine Garantie</Select.Option>
                                <Select.Option value="2">Die Hyundai Neuwagenanschlussgarantie
                                    Komplettgarantie</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Garantieinhalt">
                            <Input.TextArea autoSize={true}
                                            defaultValue={"Alle mechanischen, elektrischen, elektronischen, pneumatischen und hydraulischen Bauteile bis 200.000 km Laufleistung"}/>
                        </Form.Item>
                        <Form.Item label="Annahmerichtlinien">
                            <Input.TextArea autoSize={true}
                                            defaultValue={"bis 5 Jahre nach Erstzulassung und 100.000 km"}/>
                        </Form.Item>
                        <Form.Item label="Laufzeit">
                            <Radio.Group defaultValue={"24"}>
                                <Radio value="12">12 Monate</Radio>
                                <Radio value="24">24 Monate</Radio>
                                <Radio value="36">36 Monate</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Garantieverlängerung">
                            <Input.TextArea autoSize={true}
                                            defaultValue={"Bis ins 8. Jahr nach Erstzulassung und 120.000 km für jeweils 12 Monate möglich"}/>
                        </Form.Item>
                        <Form.Item label="Bemerkung">
                            <Input.TextArea/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Button type={"primary"} ghost={true} onClick={() => {
                    }}>Speichern</Button>
                </Col>
                <Col span={8} style={{textAlign: "center"}}>
                    <Button type={"primary"} ghost={true} onClick={() => window.print()}>Drucken</Button>
                </Col>
                <Col span={8} style={{textAlign: "right"}}>
                    <Button type={"primary"} onClick={send}>An Kunde senden</Button>
                </Col>
            </Row>
        </>
    )
}

const ProcessesCC = () => {

    const [process, setProcess] = useState<ProcessMock>();
    const [sent, setSent] = useState<boolean>(false);

    const columns: TableProps["columns"] = [{
        title: "Kunde",
        key: "customer",
        dataIndex: "customer",
        sorter: compareStrings(x => x.customer)
    }, {
        title: "Thema",
        key: "title",
        dataIndex: "title",
        sorter: compareStrings(x => x.title)
    }, {
        title: "Art",
        key: "type",
        dataIndex: "type",
        sorter: compareStrings(x => x.type)
    }, {
        title: "Fahrzeug",
        key: "vehicle",
        dataIndex: "vehicle",
        sorter: compareStrings(x => x.type)
    }, {
        title: "Bearbeiter",
        key: "handler",
        dataIndex: "handler",
        sorter: compareStrings(x => x.type),
        render: (handler: ProcessMockHandler, process: ProcessMock) => handler === ProcessMockHandler.MANUAL ? process.user : handler
    }, {
        title: "Status",
        key: "status",
        dataIndex: "status",
        sorter: compareStrings(x => x.type)
    }, {
        title: "Datum",
        key: "date",
        dataIndex: "date",
        sorter: compareStrings(x => x.date),
        render: x => formatDate(x)
    },]

    const close = (
        <Button type={"link"} size={"small"} onClick={() => {
            setProcess(undefined)
            setSent(false)
        }}>
            <FontAwesomeIcon icon={faTimes}/>
        </Button>
    )

    return (
        <>
            <Card>
                <Table columns={columns} dataSource={processMocks}
                       rowClassName={x => x.id === process?.id ? TABLE_ROW_BOLD : ""}
                       onRow={(d: ProcessMock) => {
                           return {
                               onClick: () => {
                                   setProcess(d)
                                   setSent(false)
                               }
                           };
                       }}/>
            </Card>

            {process &&
              <Card title={process.title} extra={close}>
                  {process.hasForm ? sent ? (
                          <>
                              <Alert message={"Die Informationen wurden erfolgreich an den Kunden verschickt."}/>
                          </>
                      ) : <ProcessForm send={() => setSent(true)}/> :
                      <Steps
                          current={process.status === ProcessMockStatus.OVERDUE
                              ? 0 : process.status === ProcessMockStatus.NEW
                                  ? 0 : process.status === ProcessMockStatus.IN_PROGRESS
                                      ? 2 : 3}
                          status={process.status === ProcessMockStatus.OVERDUE
                              ? "error" : process.status === ProcessMockStatus.NEW
                                  ? "wait" : process.status === ProcessMockStatus.IN_PROGRESS
                                      ? "process" : "finish"}
                          items={[
                              {
                                  title: 'Schritt A',
                              },
                              {
                                  title: 'Schritt B',
                              },
                              {
                                  title: 'Schritt C',
                              },
                          ]}
                      />}
              </Card>}
        </>
    )
}

export default ProcessesCC;